.cabinets-page {
  .game-page-title {
    margin-top: 0;
  }
}

.information-icon {
  align-self: flex-end;
  background-image: url("~information-icon.png");
  background-size: 1.1em 1.1em;
  width: 1.1em;
  height: 1.1em;
  float: right;
  z-index: 1;
}

.information-icon-yellow {
  background-color: yellow;;
}

.fa-spinner{
  color: #b71f1c !important;
}

.fa-spinner-alt{
  color: #e7e7e7 !important;
}

.fa-spinner-white{
  color: #ffffff !important;
}

.walktrough-icon {
  background-image: url("~rm_icon_walkthrough.svg");
}

.reelquick-icon {
  background-image: url("~rm_icon_reelquick.svg");
}

.sitetour-icon {
  background-image: url("~rm_icon_sitetour.svg");
}

.movie-icon {
  flex: 0 0 2em;
  width: 2em;
  height: 2em;
  margin: 0 6px;
  background-color: #323232;
  background-size: 2em;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(1);
  transform-origin: center;
  transition-duration: 100ms;
  transition-property: box-shadow, background-color, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: #8c1514;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.2);
  }
}

.stats {
  display: flex;
  flex-wrap: wrap;
}
.unsnoozed-popup .dx-toolbar-label{
  color: white !important;
}

.unsnoozed-popup-text{
  color:#323232;
  text-align: left;
}

.un-snoozed-popup-btn{
  float: right;//doesnt work we can try ... 
  background-color: #b71f1c;
  color: white;
  border-radius: 5px;
  border: none;  
}

.un-snoozed-popup-btn:hover{
  background-color: #8c1514;
}

.snoozed-textarea:focus-visible {
    outline: 2px solid #b71f1c;
    border-radius: 5px;
  }

.dx-overlay-wrapper input:focus, .dx-overlay-wrapper input:active {
  box-shadow: 0 0 5px #323232;
}

//For Toolbar headers Sitewide to make the text white
.dx-toolbar-item-content{
  color: white;
}


