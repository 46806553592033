/*
----------------------------------------
	Wipe bottom to top
----------------------------------------
*/

a:hover, a:active {
  background-color: transparent;
  text-decoration: none;
}

.venue-profile-app {
  a:hover:after, a:active:after {
    height: 100%;
  }
}
