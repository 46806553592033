.react-result-card {
  float: right;
  width: 80%;

  h2:first-of-type {
    margin-top: 0px !important;
  }

  p {
    margin-top: -9px;
  }

  select {
    width: 25%;
    font-size: 12px;
    margin: 5px 0 15px;
  }

  .active {
    background-color: $primary-color !important;

    &:hover {
      color: #424242;
      background-color: #ccc;
      border-color: transparent;
    }

    &:focus {
      color: #ffffff;
      border-color: transparent;
      box-shadow: none;
    }
  }

  article {
    line-height: 1;

    p {
      font-size: 12px;
    }

    a {
      position: relative;
    }
  }

  .result-card-section {
    margin: 5px 0 0 0;
  }

  .result-card-section-GPTW {
    display: flex;
    justify-content: space-between;
  }

  .result-card-section-GPTW {
    display: flex;
    justify-content: space-between;
  }

  .result-card-title-GPTW {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    padding-left: 11px;
    margin-bottom: 5px;
  }

  .result-card-item {
    height: auto;

    div:first-of-type {
      background-color: #fff;
    }

    mark {
      padding: 0 3px;
      background-color: #fffa03;
    }

    &:focus {
      background-color: #ccc;
      color: #424242;
      text-decoration: none;
    }
  }

  .gptw-core-title {
    margin-left: 3px;
  }

  .gptw-premium-title {
    margin-left: 26px;
  }

  .gptw-total-title {
    margin-left: 12px;
  }

  .result-card-pagination {
    font-size: 12px;

    a {
      padding: 0.25em 0.5em;
      background-color: transparent;
      text-decoration: none;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
        color: inherit;
        text-decoration: none;
        text-shadow: none;
      }

      &:focus {
        border: none;
        background-color: none;
      }
    }
    .active {
      color: #424242 !important;
    }
    .disabled {
      color: #999 !important;
    }
  }

  /* Will only works on IE11 */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    .result-card-select {
      padding: 0 0 0 10px;
      background: none;
      width: 20%;
    }
  }

  .cabinet-performance {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 0.15em;
    width: 33%;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .cabinet-performance-core {
    background-color: #2989d8;
    color: #fff;
    font-weight: bold;
    padding: 0.15em;
    width: 33%;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .cabinet-performance-premium {
    background-color: #b71f1c;
    color: #fff;
    font-weight: bold;
    padding: 0.15em;
    width: 33%;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .gptw-total-field {
    margin-left: 10px;
  }

  .gptw-total-core {
    margin-right: 5px;
  }

  .ribbon {
    position: absolute;
    right: -0.75em;
    top: -18em;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    background-color: transparent !important;
  }
  .ribbon span {
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); /* Needed for Safari */
    width: 100px;
    display: block;
    background: #2989d8;
    background: linear-gradient(#2989d8 0%, #1e5799 100%);

    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
  .ribbon span::before {
    // content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #2989d8;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #2989d8;
  }
  .ribbon span::after {
    // content: '';
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: -1;
    border-right: 3px solid #2989d8;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #2989d8;
  }
  .red span {
    background: linear-gradient(#b71f1c 0%, #811312 100%);
  }
  .red span::before {
    border-left-color: #811312;
    border-top-color: #811312;
  }
  .red span::after {
    border-right-color: #811312;
    border-top-color: #811312;
  }

  .blue span {
    background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  }
  .blue span::before {
    border-left-color: #79a70a;
    border-top-color: #79a70a;
  }
  .blue span::after {
    border-right-color: #79a70a;
    border-top-color: #79a70a;
  }
}
