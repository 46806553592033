.logo.beta {
  background-image: url("~reel-metrics-logo-beta.svg");
}

span.beta,
span.alpha {
  color: #fff;

  font-size: 60%;
  letter-spacing: 2px;
  border-radius: 0.25em;
  top: -0.25em;
  padding: 0 0.35em 0 0.35em;

  margin-left: 0.35em;
}

span.beta {
  background-color: $primary-color;
}
span.alpha {
  background-color: $alpha-color;
}

h3 span.beta,
h3 span.alpha {
  font-size: 80%;
}

span.alpha::after {
  content: "ALPHA";
}
span.beta::after {
  content: "BETA";
}
