.autocomplete {
  position: relative;
  width: 100% !important;

  background-color: white;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.3);

  overflow-y: scroll;

  > div {
    padding: 1em;

    overflow: hidden;
    cursor: pointer;
  }

  > div:nth-child(even) {
    background-color: #f3f3f3;
  }

  > div.selected {
    background-color: #e8e8e8;
  }
}

[data-identifier="autocomplete-games"] {
  width: calc(100% - 33px);
}

[data-identifier="autocomplete-glossary-nodes"] {
  width: calc(100% - 74px);
}

@include respond-min-width(43em) {
  [data-identifier="autocomplete-glossary-nodes"] {
    width: 400px;
  }
}

@include respond-min-width(60em) {
  [data-identifier="autocomplete-games"] {
    width: 400px;

    left: auto !important;
    right: 16px;
  }
}

@include respond-min-width(76em) {
  [data-identifier="autocomplete-games"] {
    right: calc((100% - 1200px) / 2);
  }
}
