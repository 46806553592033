  .custom-numberbox-style.dx-texteditor.dx-editor-outlined {
    border-radius: 4px !important;
    padding-left: 5px;
    background-color: #fffa03;
    border-color: hsl(0, 0%, 80%);
    border-width: 1px;
    border-style: solid;
  }

  .custom-numberbox-style.dx-texteditor.dx-state-focused.dx-editor-outlined, .dx-texteditor.dx-state-focused.dx-editor-outlined:hover {
    border: 2px solid #b5141c;
    border-radius: 4px !important;
    background-color: #EDEDED;
  }

  .custom-numberbox-style-wpupd{
    border-radius: 4px !important;
    background-color: #fffa03;
    border-color: hsl(0, 0%, 80%);
    border-width: 1px;
    border-style: solid;
    // padding: 0 20px;
    margin: 0 auto;
  }