@keyframes hide {
  0% {
    opacity: 1;
  }

  25% {
    animation-duration: 200ms;
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }

  25% {
    animation-duration: 200ms;
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.reports-comparison {
  position: absolute;
  top: 0;

  height: 0;
  width: 0;
  opacity: 0;

  animation-fill-mode: forwards;
  animation-duration: 250ms;
  animation-name: hide;
}

.reports-comparison.active-grid {
  position: relative;

  height: auto;
  width: auto;
  opacity: 0.9;

  animation-name: show;
}

#reportsTreeList {
  .dx-treelist-rowsview .dx-treelist-collapsed span:before,
  .dx-treelist-rowsview .dx-treelist-expanded span:before {
    left: -4px;
    top: 9px;
  }

  .dx-treelist-rowsview .dx-treelist-collapsed span:before {
    content: "\229E";
    font-size: 15px;
  }

  .dx-treelist-rowsview .dx-treelist-expanded span:before {
    content: "\2212";
  }

  .dx-treelist-rowsview .dx-treelist-collapsed span:hover::after,
  .dx-treelist-rowsview .dx-treelist-expanded span:hover::after {
    text-align: left;
    background-color: #3f3f3f;
    box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 10%);
    color: white;
    font-family: "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif !important;
    font-size: 12px;
    position: absolute;
    display: inline-block;
    z-index: 20;
    padding: 6px 12px;
    white-space: nowrap;
    margin: 0 0 0 0.75rem;
    line-height: normal;
  }

  .dx-treelist-rowsview .dx-treelist-collapsed span:hover::after {
    content: "Expand";
  }

  .dx-treelist-rowsview .dx-treelist-expanded span:hover::after {
    content: "Collapse";
  }

  .add-to-list-icon {
    left: 1.65rem;
  }

  .dx-treelist-rowsview .dx-row > td,
  .dx-treelist-rowsview .dx-row > tr > td[aria-describedby="dx-col-1"] {
    .dx-treelist-text-content {
      overflow: visible;
    }
  }

  .dx-toolbar .dx-toolbar-after {
    padding-right: 308px;
  }
}

#reportsDataGrid {
  .dx-toolbar .dx-toolbar-after {
    padding-right: 220px;
  }
}

.reports-comparison {
  #expandAll {
    position: absolute;
    top: 10px;
    right: 218px;

    z-index: 1;

    .dx-button-content {
      padding: 10px !important;
    }
  }
}

.primary-measure-filter{ 
  margin: 0 !important;
  padding: 0 !important;
  border: 0 solid white !important;
}

.scope-filters{
  margin: 0 !important;
  padding: 0 !important;
  border: 0 solid white !important;
}

.scope-filter{
padding: 10px 0;
}

.primary-measure-filter-wrapper {
  width: 75% !important; 
}

@media (min-width: 992px){
.primary-measure-filter-wrapper {
  width: 75% !important;
}
}
.niceShadow{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.reports-filter-title {
  color: $text-color;
  font-weight: bold;
  cursor: pointer;
  text-transform: capitalize !important; 
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
  margin: 0 !important;
}