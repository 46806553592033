$text-color: #2a2a2a;
$primary-color: #b71f1c;
$secondary-color: #323232;
$tertiary-color: #365e53;
$highlight-color: #fffa03;
$lowlight-color: #bababa;
$alpha-color: #f17c2e;
$bgsearch-color: #f5f5f5;
$bgmain-color: #e3e3e3;
$all-colors: #fff;
$transparent-color:rgba(255, 255, 255, 0);
$base: 14;

$paragraph-margin: 1.375em;

$padding: $paragraph-margin;
$page-padding: $paragraph-margin;
$data-padding: math.div($paragraph-margin, 2);
