.my-inventory {
  .premium {
    left: 20% !important;
  }

  .disabled-terminal {
    opacity: 0.35;
  }

  .wrapper {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;
  }

  .tutorials-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f3f3f3;
  }

  .filter-label {
    flex-shrink: 0;
    margin: 0 0 10px 0;
    font-weight: 700;
  }

  .filter-container {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;

    button {
      margin-top: 10px;
    }
  }

  .filter-wrapper {
    flex: 0 1 300px;
  }

  .filter-strategy {
    margin-bottom: 15px;
  }

  .group-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f3f3f3;
  }
}

.recommended-tutorials {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;

  .title,
  .tutorial {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .description {
    text-align: center;
  }

  .learn-icon-tutorials {
    background-image: url("~help-black.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }
}

.inventory-filters-card {
  background-color: #fff;
  height: 1243px;
  overflow: auto;
  margin-right: 20px;
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  background-color: #a8a8a8;
  cursor: pointer;
}

@media (min-width: 576px) {
  .recommended-tutorials {
    .title,
    .tutorial {
      justify-content: left;
      text-align: left;
    }

    .description {
      text-align: left;
    }
  }
}

@media (min-width: 768px) {
  .my-inventory {
    .filter-strategy {
      display: flex;
    }

    .filter-label {
      margin: 0 40px 0 0;
    }

    .filter-container {
      display: flex;
      align-items: center;

      button {
        flex-shrink: 0;
        margin-top: 0;
      }
    }

    .group-filter {
      display: flex;
    }
  }

  .flex-row {
    display: flex;
  }

  .column-left {
    flex: 1 1 auto;
    margin-right: 20px;
  }

  .column-right {
    flex: 0 1 350px;
  }

  .tutorials-wrapper {
    margin-top: 0 !important;
    padding-top: 0 !important;
    border: 0 !important;
  }

}

@media (min-width: 992px) {
  .column-left {
    margin-right: 30px;
  }
}
