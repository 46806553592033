.venue-select-advisor {
  position: absolute;
  width: 75%;
  z-index: 3;
  top: 0.8em;
  left: 45em;
}

.venue-select-advisor-two {
  position: absolute;
  width: 25%;
  z-index: 3;
  right: 6em;
}

.category-select {
  margin-left: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.venues-select {
  margin-bottom: 2px;
  width: 100%;
}

.react-select {
  margin-bottom: 2px;
}

.comparison-select {
  display: inline-block;
  width: 35%;
  left: 1.375em;
  top: 0.15em;
}