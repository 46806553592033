.no-close .ui-dialog-titlebar-close {
  display: none;
}

input#user_email{
  height: 46px !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}

input#user_password{
  height: 46px !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}

input#okta-signin-submit{
  height: 100% !important;
  border: 1px solid #ddd !important;
  padding: 0 !important;
}

.o-form-button-bar .button {
  padding: 0 !important;
}

#game-logo {
  max-width: 333px;
}

.console {
  float: right;
  font-size: 1.65em;
}

#game-details {
  padding-bottom: 1.5em;
}

#rm_tableTable_info {
  color: white;
}

#demo_preformance {
  margin-left: 0.5em;
}

#demo_play_pattern {
  margin-bottom: -0.5em;
}

#demo_deviation {
  margin-top: 3.1em;

  table {
    margin-bottom: 0;
  }

  img {
    margin: 0;
  }

  p {
    padding: 0 5px;
    font-size: 11.5886px;
    margin-bottom: 10px;
  }
}

iframe {
  height: 85%;
}

#demo_loco_title {
  margin-left: 4em;
}

.demo_loco_tab {
  width: 20em;
  margin-left: 4.5em;
  padding-right: 3.5em;
}

.demo_loco_tab_2 {
  width: 75%;
}

.demo_graph_title {
  margin-bottom: 2em;
}

#pricing_tab_tableTable {
  table-layout: fixed;
}

#pricing_tab_tableTable .column0.string {
  width: 60px;
}

.text-right {
  text-align: right;
}

.pull-right {
  float: right;
}

.no-margin {
  margin: 0;
}

.aLittleBottomMargin {
  margin: 0;
  margin-bottom: 1.2em;
}

.devExtMarginWorkAround {
  margin: 0;
  margin-bottom: 0.32em;
}

.no-padding {
  padding: 0;
}

.p-t-05 {
  padding-top: 0.5em;
}

.m-t-1 {
  margin-top: 1em;
}

.p-b-2-7 {
  margin-bottom: 2.7em;
}

.m-t-2 {
  margin-top: 2em;
}

.p-t-5 {
  padding-top: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-1 {
  margin-left: 1em;
}

.p-t-b-175 {
  padding-top: 1.75em;
  padding-bottom: 1.75em;
}

.min-h-43 {
  min-height: 43em;
}

.h-48 {
  height: 48em;
}

.clear-both {
  clear: both;
}

.display-none {
  display: none;
}

.highlight,
.dynamic-highlight {
  background-color: #ffffcc !important;
}

.help-tutorial {
  tr td:first-of-type {
    padding-left: 0;
  }
}

.game-detail-icon {
  margin-bottom: 10px;

  li img {
    width: 20px;
    margin: 0;
    vertical-align: middle;
    display: inline;
  }
}

.reason .translation_missing {
  background-color: #d50c0c;
  color: #fff;
  padding: 0.33em;
}

.legal-nav {
  color: #b71f1c;
}

.button--game [class*="--neutral"] {
  background-color: #fff;
  color: #bababa;
}

.button--game [class*="--neutral"]:hover {
  background-color: #fff;
  color: #a1a1a1;
}

h2.col.grid-item--two {
  margin-right: -15px;
}

p.quiet.col.grid-item--two {
  margin-top: 10px;
}
.play-pattern-index-text {
  margin-bottom: 15px;
}

.tools-align-right {
  text-align: right;
}

.offset.matrix.matrix--1 {
  margin-top: 38px;
}

.ppi-class {
  // border: 1px solid purple;
  width: 50px;
}

.loco-graph-border {
  border: 1px solid #000;
  padding: 15px;
}

.valued-bar.button--error {
  margin-left: -15px;
}

.loco-text-margin--left {
  // margin-right: 8px;
  // font-weight: bold;
  float: left;
}

.loco-text-margin--right {
  text-align: right;
  // font-weight: bold;
  float: right;
}

.progress {
  height: 15px;
  background-color: #f3f3f3;
  border-radius: 2px;
  width: 100%;
  padding-bottom: 21px;
}

.progress-bar {
  background-color: $primary-color;
  height: 15px;
  width: 100%;
  padding-bottom: 21px;
  justify-content: unset;
}

.progress-number {
  color: white;
  text-align: center;
  size: 5px;
  box-sizing: border-box;
  padding-top: 2.2px;
}

.game-page-title {
  padding-top: 0.6666em;
  padding-right: 1em;
  margin-bottom: -3px;
  font-size: 2.75em;
}

.game-profile-filters {
  .col--one-fifth {
    text-align: right;
  }
}

.tabs-trigger--description-grey {
  bottom: 15px;
  color: #bfbfbf;
  position: absolute;
  width: 210px;
}

.denom-border {
  border-right: #f0f0f0 2px solid;
}

.loco-score-result {
  color: $text-color;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
  align-self: flex-end;
}
.pl-0 {
  padding-left: 0 !important;
}

.pt-1 {
  padding-top: 1em;
}

.hide-select select {
  display: none;
}

.remove-offset .dataTables_wrapper {
  margin-left: 0;
  margin-right: 0;
}

.remove-filters {
  .dataTables_filter {
    display: none;
  }
}

.lifecycle-height--fixed {
  height: 53.5em;
}

.tooltips-align-right {
  text-align: right;
  bottom: 10px;
}

.advisor-filters-allign {
  display: flex;

  .search {
    margin-left: auto;
    width: 30%;
    margin-bottom: 10px;
  }
}

.footer-link-icon {
  list-style: none;
  float: right;
  margin-top: -2em;
  li {
    margin: 0.15em;
    display: inline-block;

    :first-of-type {
      margin-left: 0;
    }

    :last-of-type {
      margin-right: 0;
    }

    img {
      width: 2em;
      height: 2em;
    }
  }
}

.progress-bar-small_percentage {
  float: right;
  margin-right: 95px;
  z-index: 1;
}

.progress-bar-mixed-blend {
  color: #2a2a2a;
  mix-blend-mode: difference;
}

.unlocked {
  background-image: url("~padlock-unlock.svg");
}

.locked {
  background-image: url("~padlock-lock.svg");
}

.locked,
.unlocked {
  background-size: 59%;
  height: 2.18rem;
  width: 2.18rem;
  z-index: 1;
  float: right;
  margin: 1.12rem;
}

.content-110 {
  max-width: 110em;
}

.overflow-sheet {
  overflow: auto;
}

.icon-preview {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5em;
  height: auto;
  text-align: center;
  width: 5em;

  .material-icons {
    font-size: 2.15em;
    color: white;
    border-radius: 100%;
    padding: 0.2em;
  }

  i {
    color: white;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
  }

  .icon-text {
    font-size: 0.85em;
    color: #bababa;
    top: 0.1em;
    width: 100%;
    white-space: normal;
  }
}
