.button-wrapper {
  margin-top: 1.5em;
}
.button-group {
  padding: 0;
  margin: -0.5em -0.25em;
}

.button-group--right {
  position: absolute;
  top: 1em;
  right: 0;
}

.button-container {
  display: inline-block;
  margin: 0.5em 0.25em;
}

.button {
  display: inline-block;
  padding: 1em 1.4285714286em;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
}

.button--primary {
  background-color: $primary-color;
  color: #fff;
}

.button--primary:hover {
  background-color: darken($primary-color, 10%);
  color: #fff;
}

.button--plain {
  border-radius: 0;
}

.button--thin {
  padding: 0.5em 1em;
}

.button--right {
  margin-top: 15px;
  float: right;
}

.button--small {
  padding: 0.5em 1em;
}

.btn:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 14px;
  padding: 12px 14px;
  min-width: 140px;
}

.btn-block-mobile {
  margin-top: 15px;
}

.btn-primary {
  background-color: #b71f1c;
  border-color: #b71f1c;
  color: #fff;
}

.btn-primary:hover {
  background-color: #c3251c;
  border-color: #c3251c;
}

.btn-transparent {
  background-color: transparent;
  border-color: #b71f1c;
  color: #b71f1c;
}

.btn-white {
  background-color: #fff;
  border-color: #fff;
}

@media (max-width: 767px) {
  .btn-block-mobile {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}

@include respond-min-width(35em) {
  .form-row button[type="submit"] {
    margin-left: 20%;
  }
}
