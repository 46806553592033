.container {
  width: 100%;
}

.card {
  margin: 30px 0px;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card-panel {
  margin: 0 0 30px;
}

.card--inner {
  margin: 30px 0px;
  box-shadow: 0 2px 10px #d2d2d2;
  background-color: #fff;
}



.card-alert {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0px 25px 25px;
  white-space: normal;

  h2 {
    margin: 0px;
    padding: 0px;
    color: #b71f1c;
    font-size: 19px;
  }

  .miniviz-subtitle {
    margin: -20px 0 10px;
    font-size: 12px;
    line-height: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 24px;
    overflow: hidden;
  }
}

.card-clickable {
  position: relative;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: scale(1);
  transform-origin: center;
  transition-duration: 200ms;
  transition-property: box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:not(.card-locked) {
    &:hover {
      box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.25);
      transform: scale(1.015);
      transition-duration: 100ms;

      h2,
      h3 {
        color: #f03e3e;
      }
    }
  }
}

.card-active {
  .card-clickable:not(.card-locked) {
    box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.25);
    transform: scale(1.015);
  }
  .card {
    margin-bottom: 10px;
  }
}

.card-active:after {
  content: "";
  display: block;
  position: relative;
  left: 50%;
  bottom: -13px;
  width: 27px;
  height: 26px;
  background: #FFFFFF;
  border-right: 1px solid #CAD5E0;
  border-top: 1px solid #CAD5E0;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 1;
}

.card-header--inner {
  padding: 20px 0px;
  margin-bottom: 10px;
  white-space: initial;

  h1 {
    margin: 0px;
    padding: 0px;
    font-size: 2.75em;
  }

  h2 {
    margin: 0;
    font-size: 1.5em;
    color: #b71f1c;
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px;

  white-space: initial;
  border: 0;
  background-color: #ffffff;

  h1 {
    margin: 0px;
    padding: 0px;
    font-size: 2.75em;
  }

  h2 {
    margin: 0;
    font-size: 1.5em;
    width: 100%;
    font-weight: 500;
    color: #b71f1c;
  }
}

.card-header-material {
  background-color: #b71f1c;
  box-shadow: 0 1px 3px #d2d2d2;
  margin-bottom: 4px;
  span {
    color: white;
  }
}

.card-locked-icon {
  color: #bababa;
  position: absolute;
  top: 20px;
  right: 25px;
}

.locked-overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  button {
    max-width: 200px;
    font-weight: bold;

    &:hover {
      color: #ffff;
    }
  }
}

.card-min-height {
  min-height: 310px;
}

.CardsTabStrip {
  .card {
    margin-top: 0;
  }

  > div[class*="col-"] {
    padding: 0;
  }
}

.performance_profile_card {
  padding: 0px 7px;
}

.small-panel--gray {
  margin-top: 30px;
  background-color: #eee;

  .card-body {
    padding: 10px;
  }
}

.teaser-content {
  text-align: center;
  padding: 15px;
  display: table-cell;
  vertical-align: middle;
}

.modal-video {
  width: 672px;
}

.modal-title {
  width: 90%;
}

.modal-header {
  h2 {
    margin: 0;
    font-size: 1.5em;
  }
}

.show-grid {
  padding-top: 30px;
}

.text-title-result-card {
  .text-title-section-result-card {
    margin-left: 20px;

    :first-of-type {
      margin-left: 0px;
    }
  }

  .asset-age {
    float: right;
  }
}

@media (min-width: 992px) {
  .card-active:after {
    display: block;
  }

  .card-clickable {
    &:not(.card-locked) {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .card-active {
    .card-clickable:not(.card-locked) {
      transform: scale(1.05);
    }
  }

  .CardsTabStrip {
    > div[class*="col-"] {
      padding: 0 17px;
    }

    > div[class*="col-"]:first-of-type {
      padding-left: 0px;
    }

    > div[class*="col-"]:last-of-type {
      padding-right: 0px;
    }
  }
}
