.collapsible-arrow {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  padding: 0em;

  background-image: url("~collapsible-arrow.svg");
  background-size: contain;

  cursor: pointer;

  transition: all 0.35s;
}

.is-closed .collapsible-arrow {
  transform: rotate(-90deg);
}

.is-open .collapsible-arrow {
  transform: rotate(0deg);
}

.Collapsible {
  border-bottom: 1px solid #ddd;
}

.Collapsible__contentInner {
  padding: 0 5px;
}



.popup-arrow {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  padding: 0;
  margin-left: 1em;

  background-image: url("~collapsible-arrow.svg");
  background-size: contain;

  float: right !important;

  cursor: pointer;

  transition: all 0.35s;
}

.popup-arrow {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}

.popup-label{
  text-align: left !important;
}