.material-icons-lists,
.fa-icons-gp {
  font-size: 1.5em !important;
}

.fa-icons-gp {
  top: 0.2em;
}

.material-icons-list-items {
  top: 0.25em;
  padding-right: 0.25em;
}

.edit_list_item {
  background-image: url("~edit_list_item.svg");
  background-size: 100%;
  display: block;
  height: 0.9rem;
  width: 0.9rem;
  top: -0.8rem;
  right: 0.8rem;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editable-field {
  border: 1px solid #446db4;
  cursor: text;
}

.dx-datagrid-table td {
  font-size: 1em;
}
.dx-datagrid .dx-row > td {
  padding: 6px;
}

.tools-section {
  .lists_errors {
    background-color: #fce9e9;
    color: #b71f1c;
    width: 100%;
    padding: 0.57em;

    .error-icon {
      background-image: url("~error-icon-4.svg");
      background-size: 95%;
      display: inline-block;
      height: 2em;
      width: 2em;
      top: 0.35em;
    }

    .error-text {
      display: inline-block;
      top: -0.35em;
      left: 0.5em;
    }
  }

  .col--one-fifth {
    width: 21%;
  }

  .col--four-fifths {
    width: 79%;
    right: 1px;
  }

  .grid-filter-active {
    color: #fcd62a;
  }

  .container_wrapper {
    min-height: 42em;

    h1 {
      font-size: 2.2em;
      font-weight: 400;
      padding-top: 0px;
      padding-bottom: 0px;
      color: black;
    }

    h3 {
      font-weight: bold;
      padding-top: 1em;
    }

    h3.centering_wrapper.pl-0 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .col--one-fifth,
  .col--four-fifth,
  .lists_wrapper,
  .list_items_wrapper {
    min-height: 100%;
    height: 100%;
  }

  .lists_wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 42em;

    .icon-column {
      text-align: center !important;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
      }
    }
  }

  .dx-texteditor-input {
    font-size: 11.5px;
  }

  .centering_wrapper {
    padding-left: 2.2em;
  }

  #addNewList {
    margin: 1em 2.2em 0 0;

    .dx-button-content {
      padding: 1em 1.4285714286em;
    }

    .dx-icon-plus::before {
      font-size: 14px;
      color: #fff;
    }

    #personal_lists,
    #team_lists {
      left: -1.5em;
    }
  }

  #addNewList,
  .dx-group-panel-message,
  .dx-datagrid-search-panel {
    font-size: 11.5px;
  }

  #personalListsGridContainer,
  #sharedListsGridContainer {
    font-size: 11.5px;

    .dx-datagrid-search-panel {
      width: 10em !important;
      margin-right: 1.2em !important;
    }

    .dx-link {
      display: none;
    }

    .dx-link-save,
    .dx-link-cancel {
      display: inline-block;
      color: #333;
      opacity: 0.5;
      text-decoration: none;
    }

    .dx-state-hover td .dx-link {
      display: inline-block;
      text-decoration: none;
      color: #333;
      opacity: 0.5;
    }

    .dx-selection td .dx-link {
      color: #2a2a2a;
      opacity: 1;
    }

    .dx-selection td {
      background-color: rgba(0, 0, 0, 0.2);
      color: #2a2a2a;
    }

    tr {
      cursor: pointer;
    }
  }

  #sharedWithContainer,
  #listItemsGridContainer {
    font-size: 11.5px;
    .dx-datagrid-rowsview {
      background-color: #fbfbfb;
      min-height: 300px;
    }
  }

  #listItemsGridContainer {
    padding-left: 2.55em;
    bottom: 1.5em;
    top: 1.5em;

    .premium,
    .mixed,
    .unknown {
      left: 0;
    }

    .dx-state-hover .dx-icon-edit {
      font-size: 11.5px;
    }

    .dx-icon-edit::before {
      display: none;
    }

    .dx-state-hover .dx-icon-edit:not(.dx-focused)::before {
      display: inline;
      text-decoration: none;
      color: #333;
      opacity: 0.5;
      font-size: 11.5px;
    }

    .dx-datagrid-group-panel .dx-group-panel-message {
      padding: 7px 7px 7px 0px;
    }

    .cost-model {
      overflow: none !important;
    }
  }

  .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter:after {
    margin: 0;
  }

  #personalListsGridContainer .chart-cell,
  #sharedListsGridContainer .chart-cell,
  #listItemsGridContainer .chart-cell {
    overflow: visible;
  }

  #personalListsGridContainer span.current-value,
  #sharedListsGridContainer span.current-value,
  #listItemsGridContainer span.current-value {
    display: inline-block;
    position: relative;
  }

  #listItemsGridContainer span.diff {
    float: right;
  }

  #listItemsGridContainer .inc span.diff {
    color: #2ab71b;
  }

  #listItemsGridContainer .inc span.diff:before {
    content: "+";
  }

  #listItemsGridContainer .dec span.diff:before {
    content: "-";
  }

  #listItemsGridContainer .dec span.diff {
    color: #f00;
  }

  #listItemsGridContainer .inc span.current-value:after,
  #listItemsGridContainer .dec span.current-value:after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    right: -12px;
    top: 5px;
    background-repeat: no-repeat;
    background-size: 10px 10px;
  }
  .centering_wrapper > .selected_list_wrapper {
    display: inline-block;
  }

  #selected_list_title {
    margin: 0;
    top: -0.15em;
    max-width: 555px;
  }

  #selected_list_description {
    margin-left: 1.5em;
    max-width: 93.5%;
  }

  #selected_list_title,
  #selected_list_description {
    padding: 10px;
    display: inline-block;
  }

  .picker-selection-save,
  .inline-saving-icon,
  .inline-editing-icon {
    display: none;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
  }

  .centering_wrapper {
    .inline-saving-icon,
    .inline-editing-icon {
      top: -0.75em;
    }
  }

  #list_title_icon [class*="fa-"],
  #list_title_icon [class*="icon-"]:before {
    color: white;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0 0.25em auto auto;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: -0.55em;
  }

  #list_title_icon [class*="icon-"]:before {
    padding: 11px;
    background-color: var(--background-color);
  }

  .sharing-enabled {
    cursor: text;
  }

  .sharing-disabled {
    cursor: default;
  }

  .action_buttons_section {
    float: right;
  }

  .lists_section_buttons {
    display: inline-block;
    left: 1em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px transparent;
    -moz-box-shadow: 0 1px 3px transparent;
    box-shadow: 0 1px 3px transparent;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #212121;
    padding: 8px 18px;
    font-weight: lighter;
    font-size: 0.92857em;

    [class*="fa-"],
    [class*="icon-"]:before {
      font-size: 15px;
      line-height: 0;
      top: 1px;
    }

    [class*="icon-"]:before {
      height: 10px;
      line-height: 10px;
    }

    &:hover {
      background-color: #e6e6e6;
      border-color: #bebebe;
    }
  }

  #color-picker button {
    &:hover {
      background-color: #e6e6e6;
      border-color: #bebebe;
    }
  }

  .edit_list_description {
    top: 1em;

    textarea {
      width: 85%;
      display: inline-block;
      float: right;
      top: 0.5em;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 16px;
    top: 0.35em;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c2c2c2;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 3.5px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #8bc34a;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #8bc34a;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 16px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .list_settings_section {
    display: none;
    top: -0.5em;
    padding-left: 2.55em;

    #sharedWithContainer {
      top: 1.85em;
    }
  }

  .tooltipster-my-lists {
    top: 2em;
    clear: both;
  }

  .dx-datagrid-export-button .dx-icon-export-to:before {
    content: "\f041 ";
  }

  #deleteListItems,
  #copyListItems {
    margin-left: 5px;
  }

  .shared_with_actions {
    float: right;
    overflow: visible;
    z-index: 2;
    width: 38.5em;
    margin-top: 20px;

    #selectboxSearch,
    #deleteRole {
      display: inline-block;
    }

    #selectboxSearch {
      width: 28.5em;
    }
  }

  .dx-overlay-content .dx-popup-normal .dx-popup-draggable .dx-resizable {
    width: 35em;
    left: -22em;
  }

  .picker-wrapper{
    display: flex;
    align-items: center;
    padding-top: 2em;
  }

  .picker-selection-save {
    left: 2em;
  }

  .picker-description {
    left: 1em;
  }

  #icon-picker,
  #color-picker {
    left: -1em;
  }

  #color-picker .selected-color {
    width: 6em;
  }

  .selected-icon {
    width: 6em;
  }

  .caret-down {
    padding: 8px;
    margin-left: -4px;
    background-color: #fff;
  }
}
