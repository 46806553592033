.contact {
  .gmaps-icon {
    height: 200px;
    float: right;

    @include respond-max-width(767px) {
      float: none;
    }
  }
}
