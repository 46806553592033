.input-group {
  input,
  .input-group-btn .btn {
    padding: 6px;
    font-size: 11px;
  }
  input {
    height: 28px;
  }
}

.react-table-filter-dropdown {
  background-color: transparent;
  color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option {
    color: #555;
  }
}

.react-table-filter-wrap:after {
  content: "\ea5b" !important;
  font-family: "icomoon" !important;
  font-size: 16px;
  color: #555;
  position: absolute;
  top: 12px;
  z-index: 1;
  width: 10%;
  height: 100%;
  pointer-events: none;
}



.dropdown-menu {
  .list-group {
    overflow-y: scroll;
    max-height: 31.5em;
  }
  .list-group-item {
    display: flex;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  .highlight {
    background-color: #eeeec2 !important;
  }
}

.measure_col {
  font-weight: bold;
}
