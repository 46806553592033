.featuresTemplateWrapper .categoryWrapper > .featureWrapper.offset {
  display: block;
}

.pentaho.featuresSliderWrapper {
  text-align: right;
  margin: 10px 20px;
}

.pentaho.featuresSliderWrapper div,
.sliderWrapper div {
  display: inline-block;
}

.pentaho.featuresSliderWrapper .featuresSlider .ui-slider-handle,
.sliderWrapper .ui-slider-handle {
  position: absolute;
  top: -7px;
  margin-left: -14px;
  width: 30px;
  text-align: center;
  font-size: 10px;
  background: white;
  border: 1px solid #ddd;
  line-height: 13px;
  color: #888;
  height: 15px;
  border-radius: 10px;
  font-weight: normal;
}

.pentaho.featuresSliderWrapper .featuresSlider a,
.sliderWrapper a {
  text-decoration: none;
}

.pentaho.featuresSliderWrapper .featuresSlider a:focus,
.sliderWrapper a:focus {
  outline: none;
  text-decoration: none;
}

.pentaho.featuresSliderWrapper .featureSliderMin,
.sliderWrapper .sliderMin {
  margin-right: 18px;
  margin-left: 5px;
}

.pentaho.featuresSliderWrapper .featureSliderMax,
.sliderWrapper .sliderMax {
  margin-left: 18px;
  margin-right: 5px;
}

.pentaho.featuresSliderWrapper .featuresSlider,
.sliderWrapper .ui-slider {
  background-color: #ccc;
  margin-bottom: 4px;
  vertical-align: middle;
  height: 1px;
  width: 150px;
}
