@use "sass:math";

@import "variables";
@import "utils";
@import "normalize";
@import "link-trans";

html,
button,
datalist,
input,
select,
textarea,
.text-base,
.link-text-base:hover,
.link-text-base:active {
  color: $text-color;
}

html,
.base {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
}

html,
.text {
  line-height: 1.375;
}

body {
  font-size: 75%;
  font-family: "Roboto", Arial, sans-serif;
  line-height: 1.5;
  background-color: #f0f0f0;
}

body:after {
  content: "0em";
  display: block;
  height: 0;
  position: absolute;
  overflow: hidden;
  width: 0;
}

h1 {
  color: $primary-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title,
.title-sub,
.title-subsub {
  font-weight: normal;
}

h1,
.title {
  font-size: 2.5714285714em;
  line-height: 1;
  margin: 0 0 0.75em;
}

h2,
.title-sub {
  font-size: 1.7142857143em;
  line-height: 1.1;
  margin: 1.5em 0 0.75em;
}

h3,
.title-subsub {
  font-size: 1.3em;
}

h4,
.title-sub {
  font-size: 2em;
  color: #323232;
}

p,
ol,
ul,
blockquote,
img,
table,
form,
fieldset,
pre,
iframe,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph,
h3 {
  margin-top: $paragraph-margin;
}

p,
ol,
ul,
blockquote,
img,
table,
form,
fieldset,
pre,
iframe,
object,
embed,
video,
audio,
figure,
figcaption,
.paragraph {
  margin-bottom: $paragraph-margin;
}

ol,
ul {
  padding-left: 2em;
}

li {
  margin-bottom: math.div($paragraph-margin, 2);
  margin-top: math.div($paragraph-margin, 2);
}

h2:first-of-type,
h3:first-of-type,
p:first-of-type {
  margin-top: 0;
}

h2:last-child,
h3:last-child,
p:last-child {
  margin-bottom: 0;
}

code,
pre {
  background-color: #f3f3f3;
  padding: math.div($page-padding, 3);
}

pre > code {
  background-color: transparent;
}

pre {
  padding: 2.3em !important;
  margin-top: 1.375em !important;
  border: 1px solid #ccc;
}

a,
a:before a:after,
button:before,
button:after,
datalist,
input,
select,
textarea,
label,
.trans {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: 0.1s;
}

a {
  color: $primary-color;
  text-decoration: underline;
}

//a:visited { color: lighten($primary-color, 10%); }
a:hover,
a:active {
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.2);
  color: inherit;
  text-decoration: none;
  text-shadow: none;
}

button,
datalist,
input,
select,
textarea {
  padding: 1em 1.4285714286em;
  text-transform: none;
}

button {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

button:hover,
button:active {
  background-color: darken($primary-color, 10%);
}

datalist,
input,
select,
textarea {
  background-color: #f3f3f3;
  color: inherit;
}

textarea {
  min-height: 8em;
}

/*::selection { background-color: rgba(, , , .7); color: #fff; text-shadow: none; }*/
/*::selection:window-inactive { background-color: ; }*/

@import "default-classes";

.top {
  min-height: 100%;
  overflow: hidden;
  padding-bottom: $page-padding;
  padding-top: $page-padding;
}

.menu-nav {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.js .menu-nav {
  position: fixed;
}

.menu-nav-button,
.menu-nav-button:visited {
  display: inline-block;
  padding: 0.75em 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
}

.menu-nav-button:hover,
.menu-nav-button:active {
}

.menu-nav-button:focus {
  position: fixed;
  right: 0;
  top: 0;
}

.content-140 {
  margin-left: auto;
  margin-right: auto;
  max-width: 140em;
}

.content-130 {
  margin-left: auto;
  margin-right: auto;
  max-width: 130em;
}

.content-120 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.content-40 {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.content-900 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.page {
  padding-left: $page-padding;
  padding-right: $page-padding;
}

.page-title {
  margin-bottom: 0; /*margin-top: .5em;*/
}

.page-title-sub,
.page-title {
  display: inline-flex;
  align-items: center;
}

.page-title-sub {
  margin: 0 0.5em;
  font-size: 1.6666em;
  color: #999;
}

.game-page-title-sub {
  font-size: 2.24em;
  color: #999;
}

.show-all-link {
  margin: 10px 0px;
}

.button--game {
  margin-top: 3em;
}

.button--right {
  float: right;
}

.button.like-it-strike-it__lisi,
.js-filter .like-it-strike-it__lisi.filter-button {
  outline: none;
  background-color: #bababa;
}

.contrast {
  color: #eaeaea;
}

.contrast-link {
  color: inherit;
}

.clean {
  text-decoration: none;
}

.primary {
  color: $primary-color;
}

.shift {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.shift-element {
  display: table-caption;
}

.limit {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.quiet {
  font-size: 0.9em;
  color: #bfbfbf;
}

.quiet--darker {
  @extend .quiet;
  color: #757575;
}

.quiet--larger {
  font-size: 1.1em;
}

.emph {
  font-size: 1.1428571429em;
}

.loud {
}

.reset {
  margin: 0;
  padding: 0;
}

.continue:after,
.continue-alt:before {
  content: "\0000a0\0000a0›";
}

.scoped {
  max-width: 600px;
  max-width: 37rem;
}

@import "base";
@import "magnificpopup";

/* custom styles & utils */

.intro {
  font-size: 1.1428571429em;
}

.no-scroll {
  overflow: hidden;
}

//pre-footer
.headlines-item {
  margin: 0;
}

.headlines-item:nth-child(odd) .headlines-link {
  background-color: rgba(0, 0, 0, 0.5);
}

.headlines-link {
  color: inherit;
  display: block;
  padding: $data-padding;
  text-decoration: none;
}

.headlines-link:hover {
  background-color: transparent;
  text-decoration: underline;
}

//header
.header {
  background-color: #fff;
  padding: 0.62em 0;
  text-align: center;
}

.plain-page .header {
  border-bottom: 1px solid #ccc;
}

.plain-page .margin-header {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
  border-bottom: none;
}

.no-help-section .header {
  border-bottom: 2em solid $primary-color;
}

.nav,
.logo-container {
  display: inline-block;
  vertical-align: middle;
}

.nav-list {
  margin: 0;
  margin-left: -$page-padding;
  margin-right: -$page-padding;
}

.nav-link {
  text-decoration: none;
  color: inherit;
  padding: 1em 0.5em;
  max-width: 5em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer .nav-link {
  font-size: 0.92857em;
}

.logo {
  background-image: url("~reel-metrics-logo.svg");
  background-size: contain;
  display: block;
  width: 12.5em;
  height: 3em;
}

.logo:hover,
.logo:active {
  background-color: transparent;
}

.configs {
  float: right;
  margin-top: 1em;
}

.profile-container {
  float: right;  
  position: static;
}

.search-container .search {
  position: relative;
}

.search-container--mobile {
  position: static;
  width: 100%;
}

.search-container--mobile .search-field {
  width: 100%;
}

.search-container > form {
  margin: 0;
}

.grid-item--search > form {
  margin-top: 0;
}

.grid-item--search {
  width: 70%;
}

.search-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-right: 3em;
  background-color: transparent;
  background-image: url("~search.svg");
  background-size: 1.5em;
  text-indent: -999em;
  opacity: 0.4;
}

.search-button:hover,
.search-button:active {
  background-color: transparent;
  opacity: 1;
}

.popup-gallery {
  margin: -0.25em;
}

.popup-gallery a {
  display: block;
  float: left;
  width: 33.3333%;
  box-sizing: border-box;
  padding: 0.25em;
}

.popup-gallery a img {
  margin: 0;
  width: 100%;
}

.popup-gallery a:first-of-type {
  width: 100%;
  clear: both;
}

.cols--offset {
  margin-left: -$padding;
  margin-right: -$padding;
}

.offset,
.dataTables_wrapper,
.headlines {
  margin-left: -$data-padding;
  margin-right: -$data-padding;
}

.grid-item--offset {
  padding-left: $padding;
  padding-right: $padding;
}

@include respond-min-width(43em) {
  .grid-item--search {
    width: 100%;
    margin-top: 2.4em;
    margin-right: 2em;
  }
  .grid-item--menu-button {
    display: none;
  }

  .logo-container {
    margin-right: 1em;
  }
  .footer .nav-list {
    // margin-left: 0;
    margin-left: -$data-padding;
    margin-right: -$data-padding;
  }
  .social-list {
    margin-right: -3.5em;
  }
}

.blocklist-heading {
  padding: $data-padding;
  font-weight: bold;
  margin: 0;
}

.blocklist {
  margin-bottom: math.div($paragraph-margin, 3);
  margin-top: math.div($paragraph-margin, 3);
}

.blocklist-item {
  margin: 0;
  list-style-type: none;
}

.blocklist-item:nth-child(even) .blocklist-link {
  background-color: #dddddd;
}

.blocklist-link {
  color: inherit;
  display: block;
  font-size: 0.92857em;
  padding: $data-padding;
  background-color: #cecece;
  text-decoration: none;
}

.blocklist-item:nth-child(even) .blocklist-link:hover {
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.2);
}

//user
.user-container {
  position: fixed;
  display: none;   /* Temp for testing without it */
  top: 0;
  right: -30em;
  bottom: 0;
  width: 100%;
  max-width: 30em;
  z-index: 5; /*overflow: hidden;*/
}

.user-name-container,
.user-org-container,
.user-ven-container {
  margin: 0.25em;
}

.user-org-container {
  margin-top: 20px;
}

.user-ven-container {
  margin-bottom: 20px;
}

.user-logout {
  margin: 0.55em;
}

.user-name {
  color: inherit;
  text-decoration: none;
}

.sheet--user {
  background-color: #dddddd;
  border-left: 3px solid $primary-color;
  height: 100%;
  padding-right: 0;
}

.user-menu-button-container {
  position: absolute;
  right: 100%;
  top: 0;
  z-index: 1;
  margin: 0;
  transition: right 0.3s ease-in-out;
}

.button--user-menu {
  background-image: url("~menu.svg");
  border-radius: 0;
  width: 4em;
  height: 4em;
  text-indent: -999em;
}

.button--user-menu:hover {
  background-color: darken($primary-color, 10%);
}

.user-container.active .button--user-menu {
  background-image: url("~close.svg");
}

//game detail
.rm-locational-analysis h3.continue + div {
  margin-top: 0.45em;
}

.rm-lifecycle-analysis h3.continue + div {
  margin-top: 1.05em;
}

//reports
.console-button-container a {
  line-height: normal;
}

.grid-item--select {
  margin: -0.5em -0.25em;
}

#rm_table table {
  margin: 0;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
}

@include respond-min-width(55em) {
  .js-reports .tabs .col.grid-item--three h3 {
    margin-bottom: 2em;
  }
  // .js-reports .tabs .col--two { width: 530px; }
}

@include respond-min-width(60em) {
  #rm_table table {
    white-space: normal;
    overflow: visible;
    display: table;
  }
}

#industry_org_select_html {
  display: inline-block !important;
}

//filters
#rm_filters {
  @extend %group;
}

.js-filters-more {
  display: none;
}

.js-filters-more.active {
  display: block;
}

.js-filter-container {
  float: left;
  margin: 0.25em 0.25em 0.25em 0;
}

.js-filter-container select,
#rm_filters select,
.trailing-filters select {
  display: none;
}

.js-filter {
  display: inline-block;
  .filter-button:before {
    content: "";
    position: relative;
    display: inline-block;
    background-image: url("~filter-dropdown.svg");
    background-size: contain;
    width: 0.75em;
    height: 0.75em;
    left: -0.5em;
    top: -0.15em;
    vertical-align: baseline;
  }
  .filter-close {
    display: inline-block;
    position: absolute;
    right: 1em;
    top: 0.9em;
    text-indent: -999em;
    background-color: transparent;
    background-image: url("~filter-dropdown-close.svg");
    background-size: contain;
    width: 1em;
    height: 1em;
    padding: 0em;
    transform: rotate(180deg);
  }
  .filter-button {
    @extend .button;
    width: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 1;
    text-align: left;
    max-width: 13em;
    white-space: nowrap;
  }
  .filter-button span {
    color: $text-color;
    display: inline-block;
    max-width: 10em;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .filter-box {
    display: none;
    position: absolute;
    z-index: 3;
    min-width: 30em;
    overflow: hidden;
  }
  .filter-info {
    width: 100%;
    height: auto;
    padding: $data-padding $padding;
    background-color: $secondary-color;
  }
  .filter-title {
    margin: 0em;
    color: #fff;
    font-size: 1.25em;
  }
  .filter-description {
    padding: 1em;
    background-color: #f3f3f3;
  }
  .filter-search-box {
    margin-left: -1.375em;
    margin-right: -1.375em;
    margin-bottom: -1.375em;
  }
  .filter-search-box > #search-form {
    margin-bottom: 0em;
  }
  .filter-search-list {
    margin-bottom: 0em;
  }
  .selected-item {
    position: relative;
    width: auto;
    display: inline-block;
    margin: 0.25em;
  }
  .selected-item > span {
    display: inline-block;
    background-color: #fff;
    padding: 0.25em;
  }
  .selected-item-button {
    display: inline-block;
    vertical-align: super;
    background-color: transparent;
    width: 0.75em;
    height: 0.75em;
    padding: 0em 0em;
    margin: 0em 0em 0.25em 0.25em;
    background-image: url("~filter-close-alt.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .filter-search-list > .search-item {
    margin: 0em;
  }
  .filter-search-list > .search-item > .search {
    margin: 0em;
  }
  .filter-list {
    margin: 0em;
    overflow-y: auto;
    max-height: 25em;
  }
  .filter-item {
    margin: 0em;
    padding: 0;
  }
  .filter-item:nth-of-type(odd) {
    background-color: #fff;
  }
  .filter-item:nth-of-type(even) {
    background-color: #f7f7f7;
  }
  [class*="-input"] {
    display: inline-block;
  }
  [class*="-label"] {
    display: inline-block;
    width: 100%;
    padding: 0.6875em 0em;
    padding-right: 2em;
  }
  .radio-input {
    opacity: 0;
    margin: 0em;
    position: absolute;
    left: -999%;
  }
  .radio-label {
    padding-left: 1.375em;
  }
  .checkbox-input {
    position: absolute;
    top: 0.5em;
    left: 1.375em;
    z-index: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    height: 1.15em;
    width: 1.1em;
    background-color: white;
    border: 0.1em solid black;
    border-radius: 0.2em;
    outline: none;
  }
  .checkbox-input:checked:after {
    content: url("~check-mark.svg");
  }
  .checkbox-label {
    padding-left: 3em;
    width: 100%;
  }
  .filter-submit {
    width: 100%;
    text-align: left;
  }
  .continue-alt:before {
    content: "\0000a0›";
    padding-right: 0.5em;
  }
  .scroll-stop {
    overflow: hidden;
  }
  [class*="-input"]:checked + label {
    background-color: $primary-color;
    color: #fff;
  }
  .filter-tokens {
    margin: 0;
    padding: 0.92857em;
    padding-bottom: 0.42857em;
    list-style-type: none;
    background-color: #f3f3f3;
  }
  .filter-token {
    display: inline-block;
    background-color: #fff;
    padding: 0.5em;
    margin: 0;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.8em;
  }
  .filter-token button {
    background-color: transparent;
    padding: 0.125em;
    color: #000;
  }
  .filter-children {
    margin: 0;
  }
  .filter-item button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 0.5em;
    background-color: #323232;
    color: #fff;
    height: 100%;
  }
  .filter-item .back {
    right: auto;
    left: 0;
  }
  .filter-item .back + label {
    padding-left: 2em;
  }
  [class*="-input"]:checked ~ button {
    display: none;
  }
  .filter-list-container.searching {
    max-height: 25em;
    overflow-y: auto;
  }
  .filter-list-container.searching .filter-list {
    max-height: none;
    overflow-y: visible;
  }
  .filter-list-container.searching .filter-list.hide {
    display: block;
  }
  .filter-list-container.searching .filter-item-back {
    display: none;
  }
  .filter-item button[disabled] {
    opacity: 0.5;
  }
  .filter-item.highlight label {
    background-color: #666;
    color: #fff;
  }
}

.js-filter .filter-button:hover {
  background-color: #f3f3f3;
}

.js-filter .filter-button {
  width: 13em;
}

.js-filter.active {
  .filter-button:before {
    transform: rotate(180deg);
  }
  .filter-box {
    display: block;
    box-shadow: 0.2em 0.5em 1em rgba(0, 0, 0, 0.2);
  }
}

.js-filter.highlight .filter-button {
  background-color: $highlight-color;
}

#scope-button.tree-button-highlight,
#tree-type-button.tree-button-highlight {
  background-color: $highlight-color;
}

//more filters
.js-reports .icon--more-filters {
  /* border: 1px solid #3071de; */
  margin-top: 0.3em;
}

//footer
.footer {
  background-color: #fff;
}

.login-form {
  margin: -$paragraph-margin 0;
}

.login-meta {
  text-align: right;
  margin-left: 1em;
}

.login-meta a {
  color: #000;
}

.plain-page {
  height: 100%;
}

.plain-page .sheet-container {
  position: absolute;
  width: 100%;
}

.plain-page .sheet-container--centered {
  top: calc(50% - 9em);
}

.sheet--invalid-centered {
  top: -146px !important;
}

.plain-page {
  .sheet--valid {
    margin-top: -11.7em;
    .form-label {
      width: 26%;
    }
  }

  .sheet--invalid {
    margin-top: 1.7em;
  }

  .sheet--reset-password {
    margin-top: -2.5em;
  }

  .sheet--email-sent {
    margin-top: 3.2em;
  }

  .form-label #email {
    width: 20%;
  }

  .password-rules {
    margin-left: 1.1em;
  }

  #submit-email {
    margin-top: 1.5em;
  }

  #request-response-message,
  #save-new-password {
    margin-left: 24%;
    margin-top: 0.8em;
  }

  .success-response {
    color: #64b100;
    font-weight: bold;
    margin-left: 0 !important;
    margin-bottom: 0.8em;
  }

  .error-response {
    color: #b71f1c;
    font-weight: bold;
  }
}

//IPhone 6 plus
@media screen and (max-width: 414px) {
  .plain-page .sheet-container--centered {
    top: calc(50% - 12em);
  }
}

//IPhone 6
@media screen and (max-width: 375px) {
  .plain-page .sheet-container--centered {
    top: calc(50% - 12.5em);
  }
}

//IPhone 7
@media screen and (max-width: 320px) {
  .plain-page .sheet-container--centered {
    top: calc(50% - 13em);
  }
}

.plain-page .sheet-contact--centered {
  top: calc(50% - 25.45em);
}

//IPhone 6 plus
@media screen and (max-width: 414px) {
  .plain-page .sheet-contact--centered {
    top: calc(50% - 31.5em);
  }
}

//IPhone 6
@media screen and (max-width: 375px) {
  .plain-page .sheet-contact--centered {
    top: calc(50% - 28.5em);
  }
}

//IPhone 7
@media screen and (max-width: 320px) {
  .plain-page .sheet-contact--centered {
    top: calc(50% - 24.5em);
  }
}

.plain-page .footer .cols {
  padding: 2.2857em 0;
}

.plain-page .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ccc;
}

.plain-page .legal {
  ol,
  ul {
    padding-left: 1.5em;
  }
  li {
    padding-left: 0.25em;
  }
}

//kpi
.kpi-cols .likeableStrikeable,
.likeableStrikeable {
  width: 40px;
}

.kpi-cols .column1 {
  max-width: 4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.kpi-cols .hyperlink {
  max-width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.advisor-page .tabs-triggers,
.kpi .tabs-triggers {
  @extend %group;
}

.advisor-page .tabs-trigger-item {
  display: block;
  width: 33.333333%;
  float: left;
}

.kpi .tabs-trigger-item {
  display: block;
  width: 50%;
  float: left;
}

.advisor-page .tabs-trigger-item:first-of-type,
.advisor-page .tabs-trigger-item:nth-child(2),
.kpi .tabs-trigger-item:first-of-type {
  border-right: 0.5em solid #f0f0f0;
}

.kpi-cols {
  margin-left: -$paragraph-margin;
  margin-right: -$paragraph-margin;
}

.kpi-trends .charts {
  margin-top: 3em;
}

.kpi-trends .col.grid-item--cols {
  margin: 0;
}

.kpi-trends .grid-item--cols:nth-child(2) .charts {
  margin-top: 5.2em;
  padding: 0 3em;
}

.kpi-trends > .section-sub {
  margin-bottom: $paragraph-margin;
  margin-top: $paragraph-margin;
  border-bottom: 0.25em solid #ededed;
}

.kpi-trends > .section-sub:last-child {
  border: none;
}

.trailing-filters-container {
  padding-top: 1.375em;
  margin-top: 0.25em;
  margin-bottom: -0.75em;
}

.trailing-filters {
  float: left;
  width: 50%;
}

.trailing-filters:last-child {
  padding-left: 0.5em;
}

.global-filters {
  border-bottom: 0.5em solid #ededed;
  margin-left: -2.3em;
  margin-right: -2.3em;
  padding: 1.375em 2.3em;
}

.grid-item--user-message {
  width: 50%;
  padding-right: $data-padding;
}

.grid-item--user-message:first-of-type {
  width: 25%;
}

.grid-item--user-message:last-child {
  width: 20%;
}

.unread-message {
  font-weight: bold;
}

.read-message {
  font-weight: normmal;
  color: #666;
}

//config
.configs:before {
  content: "";
  display: inline-block;
  background-image: url("~circle-01.svg");
  width: 10px;
  height: 10px;
  margin-right: 0.5em;
  vertical-align: -0.15em;
  background-size: contain;
}

//home
.unit.sheet,
.unit.sheet .dataTables_wrapper {
  @extend %group;
}

.stock-index {
  float: left;
  width: 33.333%;
  margin-bottom: 0;
}

.rm-advisor-card .sheet-header {
  float: left;
}

.advisor,
.industry_stocks {
  h3 {
    top: -1.6em;
    margin: 0;
    padding: 0;
    font-size: 0.9em;
  }
  .tab-text {
    position: relative;
    top: -55%;
    text-align: center;
    font-weight: bold;
    font-size: 0.6em;
  }
  ul {
    position: relative;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    float: left;
    width: 20%;
    height: 130px;
    font-size: 15px;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  li.inactive {
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }
  [class^="text--"] {
    padding: 0.5em;
    text-align: right;
  }
}

.industry_stocks .group {
  left: -8px;
  width: 103%;
}

.industry_stocks li {
  width: 33.3333%;
  height: 36px;
  border: 2px solid #f0f0f0;
  border-bottom: 0;
  text-align: center;
}

.industry_stocks li.inactive {
  border-bottom: 2px solid #f0f0f0;

  opacity: 1;
  h3 {
    opacity: 0.3;
  }

  &:hover {
    opacity: 1;
    h3 {
      opacity: 0.6;
    }
  }
}

.industry_stocks li:nth-last-child(2) {
  border-left: 0;
  border-right: 0;
}

.industry_stocks li:last-child {
  padding-right: 0px;
}

.industry_stocks h3 {
  top: 0.35em;
}

.rm-market-index-card > h2 {
  margin-bottom: 1.1em;
}

#industry_stocks_wrapper table,
#advisor_table_wrapper table,
.rm-trending-reports-card table {
  margin-bottom: 0;
}

.rm-trending-reports-card {
  display: flex;
  flex-direction: column;
}

#advisor_table_wrapper table th:first-of-type {
  width: 50% !important;
}

#advisor_table_wrapper table tbody > tr > td:first-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 6em;
  white-space: nowrap;
}

.unit {
  margin-top: $page-padding;
  margin-bottom: $page-padding;
}

.unit:first-of-type {
  margin-top: 0;
}

.unit:last-child {
  margin-bottom: 0;
}

.slab .segment-sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 0 2.2em;
}

.rm-kpi-card table td.hyperlink {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 6em;
  white-space: nowrap;
}

.kpi-advisor-home + p {
  margin-bottom: $padding * 3;
}

.kpi-advisor-home table,
.kpi-home-alt table {
  margin-bottom: 0;
}

.section-main {
  margin-bottom: 5.7142857142em;
  margin-top: 5.7142857142em;
}

.section-sub {
  margin-bottom: 2.8571428571em;
  margin-top: 2.8571428571em;
}

.segment-main {
  padding-bottom: 5.7142857142em;
  padding-top: 5.7142857142em;
}

.segment-sub {
  padding-bottom: 2.8571428571em;
  padding-top: 2.8571428571em;
}

.section-row {
  margin-bottom: 1px;
  margin-top: 1px;
}

//Tools

.tabs-triggers-item--detail .sheet--2 {
  height: 6em;
}
.tabs-trigger-item--blocks {
  border-right: 0.3em solid #f0f0f0;

  &:last-child {
    border-right-width: 0;
  }
}
/*	---------------------
	DESIGN ELEMENTS
	--------------------- */

.featuresTemplateWrapperx,
.featuresTemplateWrapper {
  margin-left: -$padding;
  margin-right: -$padding;
}

.featureWrapper.odd {
  background-color: transparent;
}

.categoryWrapper h3 {
  color: $primary-color;
}

.featureWrapper {
  padding: $data-padding;
}

.expandDesc {
  margin: $data-padding 0;
}

.feature {
  font-size: 12px;
  margin: 0.05em 0 0.5em;
  color: $text-color;
}

.valued-bar.button--error,
.valued-bar {
  height: 4px;
  background-color: $primary-color;
  text-align: right;
  margin-bottom: -$data-padding;
  position: static;
}

.valued-bar span {
  display: inline-block;
  padding: 0 $data-padding;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  top: 1.1em;
  right: 0;
  color: #222;
}

.pentaho.featuresSliderWrapper.pentaho.featuresSliderWrapper {
  margin: 0 1.5em 2.2857em;
  font-size: 0.875em;
  display: inline-block;
  float: right;
}

.pentaho.featuresSliderWrapper .featuresSlider {
  height: 1px;
  background-color: #ccc;
}

#template {
  margin-top: 5em;
}

#template .feature {
  font-size: 0.92857em;
}

#template .categoryWrapper {
  width: 100%;
  padding-bottom: $padding * 3;
}

#template .categoryWrapper:nth-child(4) {
  padding-bottom: 0;
}

@include respond-min-width(33em) {
  .user-container {
    width: 80%;
    max-width: 30em;
  }
  .login-meta {
    margin-left: auto;
  }

  //parameters
  .parameters .col {
    width: 70%;
  }
  .parameters .col.first {
    width: 30%;
  }
}

@include respond-min-width(39em) {
  .kpi .tabs-trigger-item {
    display: block;
    width: 50%;
    float: left;
  }

  .advisor-page .tabs-trigger-item {
    display: block;
    width: 33.333333%;
    float: left;
  }

  .pentaho.featuresSliderWrapper.pentaho.featuresSliderWrapper {
    margin-top: -3em;
  }
  #template .categoryWrapper {
    width: 50%;
  }
  #template .categoryWrapper:nth-child(3),
  #template .categoryWrapper:nth-child(4) {
    padding-bottom: 0;
  }
}

@include respond-min-width(43em) {
  ul,
  ol {
    padding-left: 0;
  }
  .user-menu-button-container {
    top: 10.3em;
  }
  .user-container.active .user-menu-button-container {
    right: 100%;
    transition: 0s;
  }
  .button--user-menu {
    width: 3em;
    height: 3em;
    background-size: 1.5em;
  }
  
  video {
    width: auto;
  }
}

@include respond-min-width(60em) {
  .search-container {
    width: 800px;
  }
}

@include respond-min-width(74em) {
  .header {
    text-align: left;
  }

  .search-container {
    float: right;
    width: 31em;
  }

  .user-menu-button-container {
    top: 9.5em;
  }

  .nav {
    position: relative;
    bottom: auto;
    left: auto;
    width: auto;
    border: none;
  }
  .nav-link {
    max-width: none;
    padding: $data-padding;
  }

  #template .categoryWrapper {
    width: 25%;
    padding-bottom: 0;
  }
}

@include respond-min-width(80em) {
  body {
    font-size: 78%;
  }
}

.lightbox {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.lightbox-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.white-popup {
  position: relative;
  background: #fff;
  padding: 2.2857em;
  width: auto;
  max-width: 500px;
  margin: 2em auto;
}

.section-start {
  margin-top: 0;
}

.section-end {
  margin-bottom: 0;
}

.segment-start {
  padding-top: 0;
}

.segment-end {
  padding-bottom: 0;
}

.js .js-hide,
.js .js-remove,
.hide {
  display: none;
}

/*.js textarea { overflow-y: hidden; }*/
/*.js textarea, */
.js .js-cover {
  visibility: hidden;
}

.js .js-transparent {
  opacity: 0;
}

/*	---------------------
	STYLE GUIDE CLASSES
	--------------------- */

.demo {
  background-color: #ccc;
  padding: 2.2857em;
}

.styleguide pre {
  overflow: hidden;
  overflow-x: auto;
}

.styleguide .scoped {
  max-width: 44rem;
}

@import "product_stages";
@import "waves";
@import "tipsy";

.tipsy.ccc-ext-tooltip-categorical {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.tipsy-inner {
  max-width: 500px !important;
  background-color: #3f3f3f;
}

.ccc-ext-tooltip-categorical .series-detail {
  margin: 0 !important;
  padding: 0.25em;
}

.ccc-ext-tooltip-categorical .category {
  background-color: #e8e8e8;
  display: block;
  padding: 0.25em 0.5em !important;
}

.ccc-ext-tooltip-categorical.tipsy .tipsy-inner {
  border: none !important;
  color: $text-color;
  box-shadow: 0.2em 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 100px;
}

.pop {
  width: auto;
  display: inline-block;
  padding: 0.5em;
}

.pop--tip {
  max-width: 40em;
  font-size: 0.875em;
  margin: 0;
}

.pop .pop-head {
  background-color: #e8e8e8;
  font-size: 11px !important;
  display: block;
  padding: 0.25em 0.5em;
}

.pop .value-item {
  display: block;
  margin: 0.25em 0;
  text-align: left;
}

.pop .value-item .value-color {
  top: auto !important;
  margin-right: 3px;
  width: 8px !important;
  height: 8px !important;
  display: inline-block;
  position: relative !important;
}

.pop .value-item .value-color,
.pop .value-item .value-details {
  display: inline-block;
  vertical-align: middle;
}

.pop .value-detail {
  margin: 0.5em 0;
}

.pop .value-detail .label {
  color: #999;
}

.pop .value-detail .value {
  color: #666;
}

/* print */

@media print and (color) {
  // * { background: transparent !important; box-shadow: none !important; color: #000 !important; text-shadow: none !important; }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  body,
  tbody {
    background-color: #f0f0f0;
  }
  aside,
  audio,
  canvas,
  embed,
  footer,
  form,
  header,
  menu,
  nav,
  object,
  video,
  .no-print,
  #menu,
  .sheet--user,
  .header,
  .footer {
    display: none !important;
  }
  a[href]:after {
    content: " (" attr(href) ") ";
    font-size: 90%;
  }
  a[href^="/"]:after {
    content: " (http://" attr(href) ") ";
  }
  abbr:after {
    content: " (" attr(title) ")";
  }
  thead {
    display: table-header-group;
    page-break-after: avoid;
  }
  th {
    position: static;
    span {
      position: static;
    }
  }
  tr,
  img,
  pre,
  blockquote {
    page-break-inside: avoid;
  }
  tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2 {
    margin-bottom: 0.5em;
  }
  @page {
    margin: 0.9cm 0.5cm 0.9cm 0.5cm;
  }
  .col {
    float: left;
    display: inline-block;
  }
  .parameters .col {
    width: 70%;
  }
  .parameters .col.first {
    width: 30%;
  }
  .copyright-message {
    font-size: 9px;
    margin: 0.5em;
  }

  .print-page-logo {
    position: fixed;
    top: 0;
    width: 100%;

    .logo {
      background-image: url("~reel-metrics-logo.svg");
      background-size: contain;
      width: 4cm;
      height: 0.5cm;
      display: inline-block !important;
      float: right;
    }
  }
}

.valueTooltip {
  &:hover {
    &:after {
      content: attr(data-value);
      background-color: #3f3f3f;
      box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
      color: white;
      font-size: 12px;
      position: absolute;
      display: inline-block;
      z-index: 20;
      padding: 6px 12px 6px 12px;
      white-space: nowrap;
      margin: -0.37rem 0rem 0rem 0.75rem;
    }
  }
}

.locked.valueTooltip,
.unlocked.valueTooltip {
  &:hover {
    &:after {
      margin-top: 0.25rem;
      right: 105%;
    }
  }
}

.double-th {
  th {
    text-align: center;
  }
}

.table-border-right {
  border-right: solid #fff 0.35em;
}

.overflow_cell {
  overflow: visible !important;
}

@media (min-width: 576px) {
  .slab .segment-sub {
    flex-direction: row;
    align-items: flex-start;
  }
}

.btn-text{
  font-weight: bold;
  font-size: 13px;
}
