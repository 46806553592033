.tooltipster-shadow {
  height: auto !important;

  .tooltipster-arrow {
    .tooltipster-arrow-border {
      border-bottom-color: #323232 !important;
    }
  }

  .tooltipster-box {
    height: 100%;
    border-radius: 0 !important;
    box-shadow: 0.2em 0.5em 1em rgba(0, 0, 0, 0.2) !important;
  }

  .tooltipster-content {
    color: #212121 !important;
    padding: 0;
  }

  ul {
    list-style-type: none;
    margin: 1em;
    padding: 0em 1em 0em 1em;
    overflow-y: scroll;
    max-height: 25em;
    width: 25em;
  }

  li {
    display: flex;
    align-items: center;

    .fa-icons-tooltip {
      top: -0.1em;
      font-size: 1.2em;
    }

    .fa-icons-tooltip,
    label {
      margin-left: 0.75em;
    }

    label {
      flex: 0 1 auto;
      max-width: 92.5%;
    }
  }

  .plus {
    margin-right: 0.35em;
  }

  .popup_add_to {
    width: 100%;
    height: auto;
    padding: 0.6875em 1.375em;
    background-color: #323232;

    p {
      text-align: center;
      margin: 0em;
      color: #fff;
      font-size: 1em;
    }
  }

  form,
  .popup_create_new {
    border-top: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    width: 100%;
    padding: 0.6875em 1.375em;
  }

  .popup_create_new {
    height: auto;
  }

  .button_wrapper {
    width: 27%;
    float: right;
    top: -0.7em;
  }

  input[type="text"] {
    height: 1.5em;
    width: 73%;
    float: left;
    top: 0.4em;
  }

  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    input[type="text"] {
      padding: 0.3em;
      height: 2em;
    }
  }

  .create_list {
    background-image: url("~save_list.svg");
    background-size: 80%;
    margin-left: 0.35em;
  }

  .undo_list {
    background-image: url("~undo_list_2.svg");
    background-size: 119%;
  }

  .create_list,
  .undo_list {
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
  }

  input[type="checkbox"] {
    flex: 0 0 1.15em;
    z-index: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    height: 1.15em;
    width: 1.15em;
    background-color: white;
    border: 0.08em solid black;
    border-radius: 0.2em;
  }

  input[type="checkbox"]:checked:after {
    content: url("~check-mark.svg");
  }
}
