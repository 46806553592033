.printonly {
  display: none;
}

.game-details-page {
  .game-venue-performance {
    .annotation-stroke {
      stroke: #ffffff;
      stroke-width: 2;
    }

    @supports (paint-order: stroke) {
      .annotation-stroke {
        paint-order: stroke;
      }
    }
  }

  .icon-preview {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5em;
    height: auto;
    text-align: center;
    width: 5em;

    .material-icons {
      font-size: 2.15em;
      color: white;
      border-radius: 100%;
      padding: 0.2em;
    }

    [class*="fa-"],
    [class*="icon-"]:before {
      color: white;
      font-size: 18px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 100%;
      text-align: center;
      vertical-align: middle;
    }

    [class*="icon-"]:before {
      padding: 11px;
      background-color: var(--background-color);
    }

    .icon-text {
      font-size: 0.85em;
      color: #bababa;
      top: 0.1em;
      width: 100%;
    }
  }
}

@media print and (color) {
  @page {
    -webkit-print-color-adjust: exact;
    size: A4 portrait;
  }

  .content {
    min-width: 1127px;
  }
  .slab {
    background-color: #fff;
  }

  .contrast {
    color: #323232;
  }

  .button--user-menu,
  .feedback-bottom-right {
    display: none;
  }
}
