.cell--lighter-green,
.cell--light-green,
.cell--green,
.cell--bright-green,
.cell--brighter-green,
.cell--dark-green,
.cell--yellow--centralized,
.cell--pink--centralized {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: #000;
}

.cell--lighter-green {
  background-color: rgba(170, 245, 0, 0.1);
}

.cell--light-green {
  background-color: rgba(170, 245, 0, 0.2);
}

.cell--green {
  background-color: rgba(170, 245, 0, 0.4);
}

.cell--bright-green {
  background-color: rgba(170, 245, 0, 0.67);
}

.cell--brighter-green {
  background-color: rgba(170, 245, 0, 1);
}

.cell--dark-green {
  background-color: rgba(160, 220, 1, 1);
}

.cell--yellow--centralized {
  background-color: rgba(255, 255, 204, 0.6);
}

.cell--pink--centralized {
  background-color: rgba(255, 210, 199, 0.6);
}

.border-left--red {
  border-left: 2px solid red;
}

.border-right--red {
  border-right: 2px solid red;
}

.relevanceColumn.dx-datagrid-action {
  padding: 4px !important;
}

.noPaddingColumn.dx-datagrid-action.dx-cell-focus-disabled {
  padding: 4px !important;
}

.noPaddingColumn,
.relevanceColumn {
  padding: 0 !important;
}

.noPaddingColumn.dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action {
  padding: 4px !important;
}

.cell-progress-bar {
  margin: 2px 0px;
  padding-bottom: 19px;

  .progress-bar {
    height: 15px;
    font-size: 11px;
  }
}
