.home {
  tr.dx-row.dx-data-row td {
    padding: 0.6875em;
  }

  #starsAndDogsHomeTable {
    .dx-datagrid-pager {
      display: none;
    }
  }
}

#successfulConversions tr:nth-of-type(2n+1),
#successfulConversions tr:nth-of-type(2n+2) {
  background-color: #F5F5F5;
}

#successfulConversions tr:nth-of-type(4n+3),
#successfulConversions tr:nth-of-type(4n+4) {
  background-color: #fff;
}

#coversionsOverview{
  margin-top: -20px;
}

.dataTables_wrapper_flex {
  display: flex;
  justify-content: space-between;
}

.dataTables_wrapper_flex_column {
  display: flex;
  flex-direction: column;
}

.no-pager-table {
  .dx-datagrid-pager {
    display: none;
  }
}

.venues-select-home {
  width: 50% !important;
  margin: 0 0 20px auto !important;
}

.venues-select-recon {
  text-align: left !important;
  z-index: 4 !important;
}

.homeBtn {
  border: 2px solid #fff !important;
  color: #b71f1c !important;
  font-size: 11px !important;
  font-weight: 400px !important;
  font-family: -apple-system, "system-ui", "Segoe UI", "Roboto", "Noto Sans",
    Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif !important;
  text-transform: none !important;
}

.filterArrow {
  color: rgb(204, 204, 204);
  float: right !important;
  font-size: 15px !important;
  font-weight: 400px !important;
  margin-top: 3px !important;
  margin-left: 5px !important;
}

.filterArrow :hover {
  color: #4c4c4c !important;
}

.image-gallery-slide-wrapper {
  border: none !important;
}

.whiteText {
  color: #fff !important;
  text-align: center !important;
  align-self: center !important;
  justify-self: center !important;
  margin-right: 9% !important;
}

.greyText {
  color: #323232 !important;
}
.darkGreyText {
  color: #323232 !important;
}

.dataReleaseIcon {
  font-size: 12px;
}

.cvrXlsIcon {
  color: #757575 !important;
}
.cvrXlsIcon :hover{
  color: #b71f1c !important;
  background-color: #ffffff00 !important;
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}
.dx-badge {
  margin-left: 2px;
}

.dx-icon-xlsxfile {
  color: #37b24d !important;
}
// .dx-datagrid-export-button {
//   border-radius: 11% !important;
//   color: #37b24d !important;
// }

// .dx-datagrid-export-button:hover {
//   background-color: #f7f7f7 !important;
//   border-radius: 15% !important;
//   box-shadow: 1px 1px 0px #999, 2px 2px 0px #999;
//   transition: all 0.2s ease-in-out;
// }

.exportButtonFairShare{
  float: right !important;
}

.textLeft {
  text-align: left !important;
  align-self: flex-start !important;
  justify-self: flex-start !important;
  font-style: italic !important;
}

.textRight {
  text-align: right !important;
  align-self: flex-end !important;
  justify-self: flex-end !important;
  font-style: italic !important;
  padding-right: 5px;
}

.textTopRight {
  text-align: right !important;
  margin-top: -5% !important;
  align-self: flex-end !important;
}

.overViewStyledIconOne {
  font-size: 23px !important;
  color: #323232 !important;
}

.overViewStyledIconTwo {
  font-size: 25px !important;
  color: #323232 !important;
}

.overViewStyledIconBig {
  font-size: 50px !important;
  color: #323232 !important;
}

#tabpanelHome .dx-multiview-wrapper {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.card-home {
  box-shadow: 1px 1px 0px #999,
                2px 2px 0px #999,
                3px 3px 0px #999,
                4px 4px 0px #999,
                5px 5px 0px #999;
  transition: all 0.2s ease-in-out;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
  background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 57%, rgba(239,239,239,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 57%,rgba(239,239,239,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgba(255,255,255,1) 57%,rgba(239,239,239,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.card-home:hover {
  box-shadow: 1px 1px 0px #999,
                2px 2px 0px #999,
                3px 3px 0px #999,
                4px 4px 0px #999,
                5px 5px 0px #999,
                6px 6px 0px #999;
  transition: all 0.2s ease-in-out;
}

.link {
  color: #b71f1c;
  text-decoration: underline;
  cursor: pointer;
}

#search-component{
  box-shadow: 1px 1px 0px #999,
                2px 2px 0px #999,
                3px 3px 0px #999,
                4px 4px 0px #999,
                5px 5px 0px #999,
                6px 6px 0px #999;
}

.homeTable{
  margin: 0 !important;
}

.whiteText{
  color: #fff !important;
}
.rightText {
  text-align: right !important;
  float: right !important;
  color: #999 !important;
  cursor: pointer !important;
}
.rightText :hover{
  color: #b71f1c !important;
}
.greyText {
  color: #999 !important;
}
.greyText :hover{
  color: #323232 !important;
}

#TitlesPerTerminalsHomeTable{
  background-color: #ffffff00 !important;
}

.homeFairShareTables {
  margin: 0 !important;
  font-size: 14px !important;
}

.homeFairShareTables td {
  padding: 0 !important;
  margin: 0 !important;
}

.homeFairShareTables tr:nth-child(even) { 
  background-color: #f5f5f5 !important; 
}

.easyMoneyCard {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px; 
}

.easyMoneyCard td {
  padding: 0 9px 0 3px !important;
  margin: 0 !important;
  font-size: inherit; 
}

.easyMoneyCard tr:nth-child(even) { 
  background-color: #f5f5f5 !important; 
}

.easyMoneyCard tr:last-child td {
  border-bottom: none !important;
}


#noUnderline{
  text-decoration: none !important;
}

.css-x3w4s2-MuiBadge-badge{
  background-color: #b71f1c !important;
}

.betaHome {
 padding: 5px !important;
}

.physicalVideoButtons {
  text-transform: capitalize !important; 
  font-family: "Roboto", "RobotoDraft", Helvetica, Arial, sans-serif !important;
  padding-right: 5px !important;
  font-size: 1rem !important;
  text-decoration: none !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  letter-spacing: 0.00938em !important;
  text-align: center !important;
  color: #fff !important;
  background-color: #b71f1c !important;
  border: 1px solid #b71f1c !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3) !important;
  cursor: pointer !important;
}

.physicalVideoButtons :hover {
  box-shadow: 10 13px 15px 12px rgba(255, 105, 135, .3) !important;
}

.paddingIcon {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

#Suppliers .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Suppliers .dx-datagrid-headers{
  display: none !important;
  }

#GameType-Core .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#GameType-Core .dx-datagrid-headers{
  display: none !important;
  }

#GameType-Premium .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}
#GameType-Premium .dx-datagrid-headers{
  display: none !important;
  }

#Poker-Core .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Poker-Core .dx-datagrid-headers{
  display: none !important;
  }

#Poker-Premium .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
} 

#Poker-Premium .dx-datagrid-headers{
  display: none !important;
  }

#Core-Video .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Core-Video .dx-datagrid-headers{
  display: none !important;
  }

#Core-Physical .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Core-Physical .dx-datagrid-headers{
  display: none !important;
  }

#Premium-Video .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Premium-Video .dx-datagrid-headers{
  display: none !important;
  }

#Premium-Physical .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
}

#Premium-Physical .dx-datagrid-headers{
  display: none !important;
  }

#Suppliers-Denom .dx-toolbar{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-Denom .dx-toolbar-items-container{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-Denom .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-Denom .dx-datagrid-headers{
display: none !important;
}

#Suppliers-GameType .dx-toolbar{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-GameType .dx-toolbar-items-container{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-GameType .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-GameType .dx-datagrid-headers{
  display: none !important;
  }

#Suppliers-CostModel .dx-toolbar{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-CostModel .dx-toolbar-items-container{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-CostModel .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#Suppliers-CostModel .dx-datagrid-headers{
  display: none !important;
  }

#GameType-Denom-Core .dx-toolbar{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#GameType-Denom-Core .dx-toolbar-items-container{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#GameType-Denom-Core .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#GameType-Denom-Core .dx-datagrid-headers{
  display: none !important;
  }

  #Poker-Denom-Core .dx-toolbar{
    background-color: #ffffff00 !important;
    border:none !important;
    z-index: 1 !important;
  }
  
  #Poker-Denom-Core .dx-toolbar-items-container{
    background-color: #ffffff00 !important;
    border:none !important;
    z-index: 1 !important;
  }
  
  #Poker-Denom-Core .dx-datagrid-header-panel{
    background-color: #ffffff00 !important;
    border:none !important;
    z-index: 1 !important;
  }
  
  #Poker-Denom-Core .dx-datagrid-headers{
    display: none !important;
    }

    #Poker-Denom-Premium .dx-toolbar{
      background-color: #ffffff00 !important;
      border:none !important;
      z-index: 1 !important;
    }
    
    #Poker-Denom-Premium .dx-toolbar-items-container{
      background-color: #ffffff00 !important;
      border:none !important;
      z-index: 1 !important;
    }
    
    #Poker-Denom-Premium .dx-datagrid-header-panel{
      background-color: #ffffff00 !important;
      border:none !important;
      z-index: 1 !important;
    }
    
    #Poker-Denom-Premium .dx-datagrid-headers{
      display: none !important;
      }

#InsightsReport .dx-toolbar{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#InsightsReport .dx-toolbar-items-container{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#InsightsReport .dx-datagrid-header-panel{
  background-color: #ffffff00 !important;
  border:none !important;
  z-index: 1 !important;
}

#InsightsReport .dx-datagrid-headers{
  display: none !important;
  }

.greyText{
  color: #e8e8e8 !important;
}

.dx-popup-title.dx-toolbar {
  border-top: 5px solid #323232 !important;
  background-color: #323232 !important;
  color:#fff !important;
  // margin: -2px !important;
  // padding: -5px !important;
}

.dx-popup-normal  {
  border: 1px solid #323232 !important;
  box-shadow: 0 0 10px #323232 !important;
  border-radius: 5px  !important;
  background-color: #fff;
  // transition: border 5s ease-in-out !important;
}

.dx-icon-close{
  color:#fff !important;
}
.dx-icon-close::before{
  font-size: 22px !important;
}
.dx-icon-close:hover{
  color:#b71f1c !important;
}
.dxc-arg-elements  :hover{
  cursor: pointer  !important;
}
.overViewLink{
  color: #5e5e5e !important;
  text-decoration: none !important;
}
.overViewLink :hover{
  color: #b71f1c !important;
  background-color: #ffffff00 !important;
}
.tempFix  :hover{
  background-color: #ffffff00 !important;
  color: #b71f1c !important;
}

#cvrTabPanel {
  display: none !important; 
}

.reelGoodJobIcon{
  color: #323232 !important;
  font-size: 10em !important;
  padding-top: 0 !important;
}

.reelGoodJobIcon:hover{
  color: #37b24d !important;
}

.reelGoodJobIconBCheck{
  color: #37b24d !important;
}

.reelGoodJobIconB:hover{
  color: #b71f1c !important;
  cursor: pointer !important;
  scale: 1.2 !important;  
}

.reelGoodJobIconB{
  color: #c5c5c5 !important;
  font-size: 60px !important;
  padding-top: 0 !important;
}

#iconLink{
  text-decoration: none !important;
  background-color: transparent !important;
  color: #c5c5c5 !important;
}

#iconLink:hover,
#iconLink:active {
  text-decoration: none !important;
  background-color: transparent !important;
  color: #b71f1c !important;
  cursor: pointer !important;
  scale: 1.2 !important; 
}


