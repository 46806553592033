.report-slider {
  display: inline-block;
  margin-right: 2.5em;
  margin-bottom: 0.5em;
  width: 8.1em;
  left: 1.2em;
}

.report-slider p {
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}

#indicator-title,
#startDate-title {
  white-space: nowrap;
}

#startDate-max {
  margin-left: 1.3em !important;
  width: 4.6em !important;
}

#startDate-min {
  width: 4.6em !important;
}

#highlighted-sliders-placeholder {
  background-color: #ffffcc;
  display: inline-block;
}

#sliders-placeholder {
  display: inline-block;
}

.slider-wrapper {
  display: inline-block;
  background-color: #e8e8e8;
}
.slider-wrapper p {
 text-align: center;
 font-size: .85em;
 font-weight: bold;
 margin-top: 0.6875em;
 margin-bottom: 0.3em;
}

.a {
  display: inline-block;
  width: 4.1em;
  height: 1em;
  padding: 0.7em 0.3em;
  border: 1px solid #ccc;
  font-size: 0.85em;
  text-align: center;
  background-color: #ffffff;

  &:focus {
    box-shadow: inset 0 0 2px rgba(38,38,38,0.45);
    background-color: #ffffff;
  }
}

.min.a,
.max.a {
  margin-top: 1.05em;
}

.max {
  margin-left: 2.2em;
  position: absolute;
}

.noUi-base {
  width: 100%;
  height: 0.7em;
  left: 0.35em;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
}

.noUi-origin {
    position: absolute;
    height: 0;
    width: 0;
}

.noUi-horizontal .noUi-handle {
    width: 22px;
    height: 18px;
    left: -10px;
    top: -5.3px;
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-base, .noUi-handle {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-handle:before, .noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 1px;
    background: #E8E7E6;
    left: 8.5px;
    top: 3px;
}

.noUi-handle:after {
    left: 10.5px;
}

.noUi-connect {
    background: #b71f1c; //#b71f1c #3FB8AF
    box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

.noUi-connect {
    position: absolute;
    top: 0;
    bottom: 0;
  }

.noUi-horizontal .noUi-tooltip {
  display: none;
}

.noUi-horizontal.noUi-extended {
	padding-right: 32px;
}
.noUi-horizontal.noUi-extended .noUi-handle {
	left: -1px;
}
.noUi-horizontal.noUi-extended .noUi-origin  {
	right: -32px;
}

.noUi-value {
  display: none;
  top: 17px;
  position: absolute;
  text-align: center;
}

.noUi-handle {
  .noUi-horizontal & {
    cursor: ew-resize;
  }

  .noUi-vertical & {
    cursor: ns-resize;
  }
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[disabled] .noUi-connect {
  background: #b71f1c;
}

[disabled].min,
[disabled].max,
[disabled].noUi-target,
[disabled].noUi-handle {
  cursor: not-allowed;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rm-reports-sliders {
    input {
      padding: 0.3em;
      height: 2em;
    }
  }
}
