/* Container */
.ccc-ext-tooltip-categorical.tipsy .tipsy-inner {
    opacity: 1;
    background: white;
    color:#888888;
    border: 1px solid #C6C6C6;
    border-radius: 0px; -moz-border-radius:0px; -webkit-border-radius:0px;
    box-shadow: 0px 1px 5px #cccccc;
    padding: 0;
    padding-bottom:3px;
    width: auto;
    height:auto;
}

/* .ccc-ext-tooltip-categorical */
.ccc-ext-tooltip-categorical hr {
    padding: 0;
    margin:  0;
    border:none;
    border-top:1px solid #C6C6C6;
    margin-bottom:2px;
}

/* div.category <text> */
.ccc-ext-tooltip-categorical .category {
    text-align: left;
    padding: 5px 15px;
    font-size:12px;
    color:#888888;
    background-color:#f9f9f9;
}

/* table.series-detail */
.ccc-ext-tooltip-categorical .series-detail {
    border-spacing:  0px;
    border-collapse: separate;
    border: 0px;
    margin: 5px 15px;
    min-width: 100px;
}

.ccc-ext-tooltip-categorical .series-detail td {
    position: relative;
    vertical-align: baseline;
    padding: 8px 0 0 0;
    border: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #f3f3f3;
}

.ccc-ext-tooltip-categorical .series-detail td.value-detail {
    border: 0px;
}

.ccc-ext-tooltip-categorical .series-detail tr.series-_total_ td {
    font-weight: bold;
}

.ccc-ext-tooltip-categorical .series-detail tr:nth-last-child(1) td {
    border: 0px;
}

/* table.series-detail td div.color - has a predefined bg color set */
.ccc-ext-tooltip-categorical .series-detail .color {
    position: absolute;
    top:    8px;
    height: 2px;
    width:  25px;
}

/* table.series-detail td div.series <text> */
.ccc-ext-tooltip-categorical .series-detail .series {
    text-align: left;
    font-size: 8px;
    color:#777777;
}



/* table.series-detail td div.value <text> */
.ccc-ext-tooltip-categorical .value-detail .label {
    float: left;
    text-align: left;
    margin-right: 5px;
    font-size: 10px;
    color:#777777;
    line-height: 1em;
    padding: 2px 0px;
}

/* table.series-detail td div.value <text> */
.ccc-ext-tooltip-categorical .value-detail .value {
    float: right;
    text-align: right;
    font-size: 10px;
    color:#777777;
    line-height: 1em;
    padding: 2px 0px;
}

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
}

.tooltipster-content {
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-sizer {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}






/* DEFAULT THEME OF THE DEFAULT DISPLAY PLUGIN */

/* .tooltipster-box */

.tooltipster-box {
	background: #565656;
	border: 2px solid black;
	border-radius: 4px;
}

.tooltipster-bottom .tooltipster-box {
	margin-top: 8px;
}

.tooltipster-left .tooltipster-box {
	margin-right: 8px;
}

.tooltipster-right .tooltipster-box {
	margin-left: 8px;
}

.tooltipster-top .tooltipster-box {
	margin-bottom: 8px;
}


/* .tooltipster-content */

.tooltipster-content {
	color: white;
	line-height: 18px;
	padding: 6px 14px;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-arrow {
	overflow: hidden;
	position: absolute;
}

.tooltipster-bottom .tooltipster-arrow {
	height: 10px;
	/* half the width, for centering */
	margin-left: -10px;
	top: 0;
	width: 20px;
}

.tooltipster-left .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	right: 0;
	/* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
	been positioned yet */
	top: 0;
	width: 10px;
}

.tooltipster-right .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	left: 0;
	/* same as .tooltipster-left .tooltipster-arrow */
	top: 0;
	width: 10px;
}

.tooltipster-top .tooltipster-arrow {
	bottom: 0;
	height: 10px;
	margin-left: -10px;
	width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-arrow-background, .tooltipster-arrow-border {
	height: 0;
	position: absolute;
	width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-arrow-background {
	border: 10px solid transparent;
}

.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #565656;
	left: 0px;
	top: 3px;
}

.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #565656;
	left: -3px;
	top: 0px;
}

.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #565656;
	left: 3px;
	top: 0px;
}

.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #565656;
	left: 0px;
	top: -3px;
}

/* .tooltipster-arrow-border */

.tooltipster-arrow-border {
	border: 10px solid transparent;
	left: 0;
	top: 0;
}

.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: black;
}

.tooltipster-left .tooltipster-arrow-border {
	border-left-color: black;
}

.tooltipster-right .tooltipster-arrow-border {
	border-right-color: black;
}

.tooltipster-top .tooltipster-arrow-border {
	border-top-color: black;
}

/* tooltipster-arrow-uncropped */

.tooltipster-arrow-uncropped {
	position: relative;
}

.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -10px;
}

.tooltipster-right .tooltipster-arrow-uncropped {
	left: -10px;
}
/* This theme was made for Tooltipster's default display plugin */

th.likeableStrikeable {cursor: default;}
th.likeableStrikeable.sorting {cursor: default !important;}
th.likeableStrikeable:after {cursor: default !important;background-image: none;}
iframe{margin-top: 0;margin-bottom: 0;}
.grid-item--search {display:none;}

.matrix {
    background: #b71f1c;
    padding-top: 10px;
    padding-right: 25px;
}
.matrix tr:nth-child(even) {
    background: #fff;
}
.matrix__table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    margin-left: auto;
    margin-bottom: 0;
}
.matrix__th {
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
    width: 25px;
    background: #b71f1c;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
.matrix tr:last-child .matrix--th:last-child {
    border-right: 1px solid #b71f1c;
}
.matrix__td {
    position: relative;
    width: auto;
    height: 25px;
    border: 1px solid #bfbfbf;
    background: #fff;
    text-align: center;
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
}
.matrix__td:first-child {
    background: #b71f1c;
    border: none;
}
.matrix__td--crosshair:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    top: -1px;
    left: 0;
    background-color: #bfbfbf;

}
.matrix__td--crosshair:last-child:after {
    content: "mean";
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 100%;
    top: -50%;
    left: 55%;
    color: #fff;
    background: none;
    text-align: center;
    font-size: 8px;
    transform: rotateZ(90deg);
}
.matrix__table tr:first-child .matrix__td--crosshair-column:before {
    content: "mean";
    position: absolute;
    width: 35px;
    margin-left: -50%;
    left: 100%;
    top: -20px;
    color: #fff;
    background: none;
    text-align: center;
    font-size: 8px;
}
.matrix__td--crosshair-column:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 2px;
    top: 0;
    right: -1px;
    background-color: #bfbfbf;
}
.matrix__td--selected {
    background: #b71f1c;
    color: #fff;
    z-index: 1;
}
.matrix__td--quadrant {
    border-left-color: #fff;
}
.matrix__td--quadrant:before {
    display: block;
    vertical-align: middle;
    position: absolute;
    background: #fff;
    top: 0;
    right: -1px;
    bottom: 0;
    left: -1px;
    text-align: center;
    color: #bfbfbf;
    text-transform: uppercase;
    padding-top: 5px;
    font-size: 14px;
    font-weight: bold;
    width: 200%;
    text-align: center;
    left: -50%;
}
.matrix__td--quadrant--1:before {
    content: 'CAT I';
    z-index: 1;
}
.matrix__td--quadrant--2:before {
    content: 'CAT II';
    z-index: 1;
}
.matrix__td--quadrant--3:before {
    content: 'CAT III';
    z-index: 1;
}
.matrix__td--quadrant--4:before {
    content: 'CAT IV';
    z-index: 1;
}
.matrix__td--quadrant.matrix__td--selected:before {
    display: none;
}

.matrix__td--quadrant.matrix__td--selected:after {
}
.matrix__left-header {
	position: absolute;
	padding-bottom: 2px;
	width: 100%;
	top: 57%;
	left: -47.8%;
	color: #b71f1c;
	background: #fff;
	text-align: center;
	transform: rotateZ(-90deg);
}
.matrix__bottom-header {
    text-align: center;
    color: #b71f1c;
    background: #fff;
    width: 115%;
}

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
}

.tooltipster-content {
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-sizer {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}






/* DEFAULT THEME OF THE DEFAULT DISPLAY PLUGIN */

/* .tooltipster-box */

.tooltipster-box {
	background: #565656;
	border: 2px solid black;
	border-radius: 4px;
}

.tooltipster-bottom .tooltipster-box {
	margin-top: 8px;
}

.tooltipster-left .tooltipster-box {
	margin-right: 8px;
}

.tooltipster-right .tooltipster-box {
	margin-left: 8px;
}

.tooltipster-top .tooltipster-box {
	margin-bottom: 8px;
}


/* .tooltipster-content */

.tooltipster-content {
	color: white;
	line-height: 18px;
	padding: 6px 14px;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-arrow {
	overflow: hidden;
	position: absolute;
}

.tooltipster-bottom .tooltipster-arrow {
	height: 10px;
	/* half the width, for centering */
	margin-left: -10px;
	top: 0;
	width: 20px;
}

.tooltipster-left .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	right: 0;
	/* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
	been positioned yet */
	top: 0;
	width: 10px;
}

.tooltipster-right .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	left: 0;
	/* same as .tooltipster-left .tooltipster-arrow */
	top: 0;
	width: 10px;
}

.tooltipster-top .tooltipster-arrow {
	bottom: 0;
	height: 10px;
	margin-left: -10px;
	width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-arrow-background, .tooltipster-arrow-border {
	height: 0;
	position: absolute;
	width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-arrow-background {
	border: 10px solid transparent;
}

.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #565656;
	left: 0px;
	top: 3px;
}

.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #565656;
	left: -3px;
	top: 0px;
}

.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #565656;
	left: 3px;
	top: 0px;
}

.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #565656;
	left: 0px;
	top: -3px;
}

/* .tooltipster-arrow-border */

.tooltipster-arrow-border {
	border: 10px solid transparent;
	left: 0;
	top: 0;
}

.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: black;
}

.tooltipster-left .tooltipster-arrow-border {
	border-left-color: black;
}

.tooltipster-right .tooltipster-arrow-border {
	border-right-color: black;
}

.tooltipster-top .tooltipster-arrow-border {
	border-top-color: black;
}

/* tooltipster-arrow-uncropped */

.tooltipster-arrow-uncropped {
	position: relative;
}

.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -10px;
}

.tooltipster-right .tooltipster-arrow-uncropped {
	left: -10px;
}
/* This theme was made for Tooltipster's default display plugin */

.tooltipster-shadow .tooltipster-box {
	border: none;
	border-radius: 5px;
	background: white;
	box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
}

.tooltipster-shadow.tooltipster-bottom .tooltipster-box {
	margin-top: 6px;
}

.tooltipster-shadow.tooltipster-left .tooltipster-box {
	margin-right: 6px;
}

.tooltipster-shadow.tooltipster-right .tooltipster-box {
	margin-left: 6px;
}

.tooltipster-shadow.tooltipster-top .tooltipster-box {
	margin-bottom: 6px;
}

.tooltipster-shadow .tooltipster-content {
	color: #8d8d8d;
}

.tooltipster-shadow .tooltipster-arrow {
	height: 6px;
	margin-left: -6px;
	width: 12px;
}

.tooltipster-shadow.tooltipster-left .tooltipster-arrow,
.tooltipster-shadow.tooltipster-right .tooltipster-arrow {
	height: 12px;
	margin-left: 0;
	margin-top: -6px;
	width: 6px;
}

.tooltipster-shadow .tooltipster-arrow-background {
	display: none;
}

.tooltipster-shadow .tooltipster-arrow-border {
	border: 6px solid transparent;
}

.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: white;
}

.tooltipster-shadow.tooltipster-left .tooltipster-arrow-border {
	border-left-color: white;
}

.tooltipster-shadow.tooltipster-right .tooltipster-arrow-border {
	border-right-color: white;
}

.tooltipster-shadow.tooltipster-top .tooltipster-arrow-border {
	border-top-color: white;
}

.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -6px;
}

.tooltipster-shadow.tooltipster-right .tooltipster-arrow-uncropped {
	left: -6px;
}

.tooltipster-noir .tooltipster-box {
	border: none;
	border-radius: 0px;
	background: #3F3F3F;
	box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
}

.tooltipster-noir.tooltipster-bottom .tooltipster-box {
	margin-top: 6px;
}

.tooltipster-noir.tooltipster-left .tooltipster-box {
	margin-right: 6px;
}

.tooltipster-noir.tooltipster-right .tooltipster-box {
	margin-left: 6px;
}

.tooltipster-noir.tooltipster-top .tooltipster-box {
	margin-bottom: 6px;
}

.tooltipster-noir .tooltipster-content {
	color: #FFF;
}

.tooltipster-noir .tooltipster-arrow {
	height: 6px;
	margin-left: -6px;
	width: 12px;
}

.tooltipster-noir.tooltipster-left .tooltipster-arrow,
.tooltipster-noir.tooltipster-right .tooltipster-arrow {
	height: 12px;
	margin-left: 0;
	margin-top: -6px;
	width: 6px;
}

.tooltipster-noir .tooltipster-arrow-background {
	display: none;
}

.tooltipster-noir .tooltipster-arrow-border {
	border: 0px solid transparent;
}

.tooltipster-noir.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: none;
}

.tooltipster-noir.tooltipster-left .tooltipster-arrow-border {
	border-left-color: none;
}

.tooltipster-noir.tooltipster-right .tooltipster-arrow-border {
	border-right-color: none;
}

.tooltipster-noir.tooltipster-top .tooltipster-arrow-border {
	border-top-color: none;
}

.tooltipster-noir.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -6px;
}

.tooltipster-noir.tooltipster-right .tooltipster-arrow-uncropped {
	left: -6px;
}

/*Tooltipster default orange*/
.tooltipster-gray .tooltipster-box {
	border: none;
	border-radius: 0px;
	background: #3f3f3f;
	box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
}

.tooltipster-gray.tooltipster-bottom .tooltipster-box {
	margin-top: 6px;
}

.tooltipster-gray.tooltipster-left .tooltipster-box {
	margin-right: 6px;
}

.tooltipster-gray.tooltipster-right .tooltipster-box {
	margin-left: 6px;
}

.tooltipster-gray.tooltipster-top .tooltipster-box {
	margin-bottom: 6px;
}

.tooltipster-gray .tooltipster-content {
	color: white;
}

.tooltipster-gray .tooltipster-arrow {
	height: 6px;
	margin-left: -6px;
	width: 12px;
}

.tooltipster-gray.tooltipster-left .tooltipster-arrow,
.tooltipster-gray.tooltipster-right .tooltipster-arrow {
	height: 12px;
	margin-left: 0;
	margin-top: -6px;
	width: 6px;
}

.tooltipster-gray .tooltipster-arrow-background {
	display: none;
}

.tooltipster-gray .tooltipster-arrow-border {
	border: 6px solid transparent;
}

.tooltipster-gray.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: #3f3f3f;
}

.tooltipster-gray.tooltipster-left .tooltipster-arrow-border {
	border-left-color: #3f3f3f;
}

.tooltipster-gray.tooltipster-right .tooltipster-arrow-border {
	border-right-color: #3f3f3f;
}

.tooltipster-gray.tooltipster-top .tooltipster-arrow-border {
	border-top-color: #3f3f3f;
}

.tooltipster-gray.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -6px;
}

.tooltipster-gray.tooltipster-right .tooltipster-arrow-uncropped {
	left: -6px;
}

/*Tooltipster default orange end*/

/*Tooltipster borderless for testing it*/
.tooltipster-sidetip.tooltipster-borderless .tooltipster-box {
    border: none;
    background: #1b1b1b;
    background: rgba(10, 10, 10, .9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-box {
    margin-top: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-box {
    margin-right: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-box {
    margin-left: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-box {
    margin-bottom: 8px
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow {
    height: 8px;
    margin-left: -8px;
    width: 16px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow {
    height: 16px;
    margin-left: 0;
    margin-top: -8px;
    width: 8px
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-background {
    display: none
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-border {
    border: 8px solid transparent
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #1b1b1b;
    border-bottom-color: rgba(10, 10, 10, .9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #1b1b1b;
    border-left-color: rgba(10, 10, 10, .9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #1b1b1b;
    border-right-color: rgba(10, 10, 10, .9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #1b1b1b;
    border-top-color: rgba(10, 10, 10, .9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-uncropped {
    left: -8px
}

/*tooltipster borderless end*/
.pricingtooltip .tipsy-inner {
    padding: 4px;
    box-shadow: 0px 1px 5px #cccccc;
    background: orange;
    color: #000;
}

.pricingtooltip .tipsy-inner .emph {
    padding-left: 5px;
    font-weight: 900;
}

.tipsy-inner {
    max-width: 300px;
    text-align: center;
}
.tipsy-inner hr {
    margin-top: 3px;
    margin-bottom: 3px;
}
.ccc-ext-tooltip-categorical.tipsy {
    width: auto;
    opacity: 1;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing:0.05em
}

.ccc-ext-tooltip-categorical.tipsy .tipsy-arrow{
    display: none;
    visibility: hidden;
}

/* Container */
.ccc-ext-tooltip-categorical.tipsy .tipsy-inner {
    opacity: 1;
    background: white;
    color:#888888;
    border: 1px solid #C6C6C6;
    border-radius: 0px; -moz-border-radius:0px; -webkit-border-radius:0px;
    box-shadow: 0px 1px 5px #cccccc;
    padding: 0;
    padding-bottom:3px;
    width: auto;
    height:auto;
}

/* .ccc-ext-tooltip-categorical */
.ccc-ext-tooltip-categorical hr {
    padding: 0;
    margin:  0;
    border:none;
    border-top:1px solid #C6C6C6;
    margin-bottom:2px;
}

/* div.category <text> */
.ccc-ext-tooltip-categorical .category {
    text-align: left;
    padding: 5px 15px;
    font-size:12px;
    color:#888888;
    background-color:#f9f9f9;
}

/* table.series-detail */
.ccc-ext-tooltip-categorical .series-detail {
    border-spacing:  0px;
    border-collapse: separate;
    border: 0px;
    margin: 5px 15px;
    min-width: 100px;
}

.ccc-ext-tooltip-categorical .series-detail td {
    position: relative;
    vertical-align: baseline;
    padding: 8px 0 0 0;
    border: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #f3f3f3;
}

.ccc-ext-tooltip-categorical .series-detail td.value-detail {
    border: 0px;
}

.ccc-ext-tooltip-categorical .series-detail tr.series-_total_ td {
    font-weight: bold;
}

.ccc-ext-tooltip-categorical .series-detail tr:nth-last-child(1) td {
    border: 0px;
}

/* table.series-detail td div.color - has a predefined bg color set */
.ccc-ext-tooltip-categorical .series-detail .color {
    position: absolute;
    top:    8px;
    height: 2px;
    width:  25px;
}

/* table.series-detail td div.series <text> */
.ccc-ext-tooltip-categorical .series-detail .series {
    text-align: left;
    font-size: 8px;
    color:#777777;
}



/* table.series-detail td div.value <text> */
.ccc-ext-tooltip-categorical .value-detail .label {
    float: left;
    text-align: left;
    margin-right: 5px;
    font-size: 10px;
    color:#777777;
    line-height: 1em;
    padding: 2px 0px;
}

/* table.series-detail td div.value <text> */
.ccc-ext-tooltip-categorical .value-detail .value {
    float: right;
    text-align: right;
    font-size: 10px;
    color:#777777;
    line-height: 1em;
    padding: 2px 0px;
}

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
}

.tooltipster-content {
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-sizer {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
	width: auto !important;
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}
