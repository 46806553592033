.tooltipster-information-icon {
  margin-top: 1.5em;

  &:before {
    background-image: url("~information-icon.png");
    background-size: 0.8em 0.8em;
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    content: "";
  }
}

.tooltipster-alert-icon {
  &:before {
    display: inline-block;
    content: " ";
    background-image: url("~alert-icon.svg");
    background-size: 22px 22px;
    height: 22px;
    width: 22px;
    vertical-align: text-bottom;
  }
}
.tooltipster-paginate {
  margin-top: -3em;
  z-index: 2;
  max-width: 25%;
  margin-left: 75%;
}

// Tooltipster Sliders Styling
.tooltipster-shadow {
  .sliderDesc {
    font-size: 12px;
  }

  .ui-slider-handle {
    background-color: #eee;
    color: #000;
  }
}

.tooltipster-black {
  &:hover {
    &:after {
      content: attr(data-value);
      background-color: #3f3f3f;
      box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
      color: white;
      font-size: 12px;
      position: absolute;
      display: inline-block;
      z-index: 20;
      padding: 6px 12px 6px 12px;
      white-space: nowrap;
      margin: -0.37rem 0rem 0rem 1.4rem;
    }
  }
}

/* Newest React Tooltips */
.tooltip-inner {
  max-width: 300px;
  text-align: left;
}
