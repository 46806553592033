.dx-datagrid-table td {
  font-size: 1em;
  font-family: "Roboto", Arial, sans-serif;
}
.dx-datagrid .dx-row > td {
  padding: 6px;
}

.dx-datagrid > td,
.dx-datagrid > tr > td {
  background-color: transparent;
}

.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td,
.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  background-color: rgba(0, 0, 0, 0.04);
}

.dx-datagrid .dx-row.row-highlighted > td,
.dx-datagrid .dx-row.row-highlighted > tr > td,
.dx-datagrid .dx-row-alt.row-highlighted > td,
.dx-datagrid .dx-row-alt.row-highlighted > tr > td {
  background-color: rgba(255, 234, 145, 0.5);
}

.dx-datagrid div.dx-page.page-highlighted:not(.dx-selection) {
  background-color: rgba(255, 234, 145, 0.5);
}

.dx-datagrid div.dx-page.dx-selection.page-highlighted {
  background-color: rgba(199, 187, 137, 0.5);
}

.dx-widget {
  font-size: 11px;
}

.dx-row-focused td a {
  color: #f8f9fa !important;
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  background-color: #a8a8a8;
  cursor: pointer;
}
.dx-header-filter {
  text-shadow: -1px 0 #7f7f7f, 0 1px #7f7f7f, 1px 0 #7f7f7f, 0 -1px #7f7f7f;
}
.dx-datagrid .dx-header-filter,
.dx-checkbox-icon {
  color: #fffa03;
}
.dx-datagrid .dx-header-filter-empty {
  color: #d0d0d0;
}

.dx-column-indicators {
  float: right !important;
}

.dx-header-row > td:not(td.dx-command-select) {
  text-align: left !important;
}

.dx-row-focused > td {
  background-color: #c92a2a !important;
  color: #f8f9fa !important;
}

.yellowFocusedRow .dx-row-focused > td {
  background-color: #fffa03 !important; /* Yellow */
  color: #323232 !important;
  border: 1px solid #fffa03 !important;
}

.yellowFocusedRow .dx-row-focused > td > a{
  color: #323232 !important;
}

.yellowFocusedRow .dx-datagrid-group-opened {
  color: #323232 !important;
  padding-bottom: 25px !important;
}

.yellowFocusedRow .dx-datagrid-group-closed {
  color: #323232 !important;
}

.yellowFocusedRow td .premium.valueTooltip{
  background-image: url("~cost-model-03.svg");
}

.focusedRow .dx-row-focused > td {
  background-color: #fffa03;
  color: #323232;
}

.focusedRow .dx-row-focused > td > a,
.focusedRow .dx-datagrid-group-opened,
.focusedRow .dx-datagrid-group-closed {
  color: #323232;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell:first-of-type {
  background-color: #fff !important;
  box-shadow: inset 7px 7px 7px -5px rgba(0, 0, 0, 0.75);
}

.dx-toolbar-label {
  color: #b71f1c;
}

.dx-datagrid-table .dx-dropdowneditor-input-wrapper {
  border: 1px hsl(0, 0%, 80%) solid;
  border-radius: 6px;
}

.dx-popup-normal {
  overflow: visible;
}

.dx-textarea textarea {
  min-height: 8em;
}

.dx-texteditor.dx-state-focused {
  border: 2px solid #b91d1a;
}

.dx-menu-horizontal {
  margin-top: 5px;
}

.dx-menu-item.dx-state-hover {
  background-color: #d2d2d2;
}

.dx-menu-item-popout {
  font-size: 22px;
}

.dx-menu-base .dx-menu-item-content {
  padding: 9px;
}

.dx-menu-item-content a {
  text-decoration: none !important;
  font-size: 13px;
  color: #2a2a2a;
  font-family: "Roboto", Arial, sans-serif;
}

.dx-menu-item-content a:hover {
  background-color: inherit;
}

.dx-expand:before {
  margin-top: -12px !important;
  margin-left: -12px !important;
}

.dx-expand-icon-container {
  margin-right: 5px !important;
}

.dx-pivotgrid-fields-area {
  background-color: #fff;
  .dx-area-field-container {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  .dx-pivotgrid-fields-area-head {
    td {
      background-color: #fff;
    }
  }
}

.dx-page-sizes:before {
  content: "Results per Page";
}

.dx-pivotgrid-fields-area {
  margin-left: 0 !important;
}

.dx-pivotgrid-background {
  background-color: #fff !important;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 60px;
  overflow: visible;
}

.dx-tabpanel-tabs {
  z-index: 1;
}

.dx-scrollable-native.dx-scrollable-both,
.dx-scrollable-native.dx-scrollable-both .dx-scrollable-container {
  background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 0% 50%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 100% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 45px 100%, 45px 100%, 20px 100%, 20px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
}

#listItemsGridContainer {
  .dx-datagrid-headers {
    .dx-header-row {
      background-color: #e8e8e8;
      color: #2a2a2a;
      font-weight: bold;

      td[rowspan] {
        text-align: center !important;
        vertical-align: left;
      }
    }
  }

  .dx-toolbar-after {
    display: flex;
    align-items: center;
    min-width: 346px;
  }
}

.dx-button-content {
  padding: 12px 5px 5px 5px !important;
  .dx-icon {
    font-size: 22px !important;
    width: 26px !important;
    height: 21px !important;
  }
}

.dx-closebutton {
  .dx-icon-close {
    font-size: 12px !important;
    margin-left: -8px;
    margin-top: -12px;
  }
}

.dx-texteditor-input {
  height: 36px;
  font-size: 1.2em;
}

.dx-field {
  width: 80%;
}
.dx-field-label {
  width: 20%;
}
.dx-field-value {
  width: 80%;
}
.dx-checkbox {
  z-index: 1;
}
.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td
  > .dx-select-checkbox {
  display: block;
}

.dx-datagrid-headers {
  .dx-header-row {
    background-color: #e8e8e8;
    color: #2a2a2a;
    font-weight: bold;
    &:not(:last-child) {
      background-color: #b91c1b;
      color: #f0f0f0;
    }
    td[rowspan] {
      text-align: center !important;
      vertical-align: middle;
    }
  }

  .dx-datagrid-text-content.dx-text-content-alignment-right.dx-header-filter-indicator {
    float: left; //These are the columns names, they should always be left aligned even if the table try to set it to right
  }
}

.dx-pager {
  .dx-pages {
    display: flex;
    align-items: center;
  }

  .dx-page-sizes::before {
    margin-right: 10px;
  }
}

#gridContainerAdvisor .dx-datagrid-header-panel .dx-toolbar
  {
  margin-bottom: 0 !important;
}
