h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  color: #b71f1c;
  white-space: initial;
}
.notification--error,
.notification--success {
  h3 {
    color: #fff;
  }
}

.box--primary h1,
.box--primary h2,
.box--primary h3,
.box--primary h4,
.box--primary h5,
.box--primary h6 {
  color: #fff;
}

h1:first-of-type,
h3:first-of-type,
h4:first-of-type,
h5:first-of-type,
h6:first-of-type {
  margin-top: 0;
}

.title {
  display: block;
  font-weight: bold;
}

.title--explanation {
  color: #b2b6b7;
  font-weight: bold;
  font-size: 18px;
}

.title--primary {
  color: #b71f1c;
}

.title--white {
  color: #fff;
}

.title--xlg {
  margin-bottom: 10px;
  font-size: 38px;
}

@media (min-width: 48em) {
  .title--xlg {
    font-size: 64px;
  }
}

@media (min-width: 1200px) {
  .title--xlg {
    font-size: 72px;
  }
}

.title--lg {
  margin-bottom: 15px;
  font-size: 32px;
}

@media (min-width: 48em) {
  .title--lg {
    font-size: 52px;
  }
}

.title--md {
  font-size: 36px;
}

.title--sm {
  font-size: 20px;
}

.paragraph {
  line-height: 1.4;
  color: #686d70;
  font-size: 17px;
  white-space: initial;
}

@media (min-width: 48em) {
  .paragraph {
    font-size: 20px;
  }
}

.paragraph--extra-smallest {
  font-size: 14px;
  line-height: 1;
}

.paragraph--smallest {
  font-size: 16px;
  line-height: 1.625;
}

.paragraph--small {
  font-size: 18px;
  line-height: 1.555;
}

.paragraph--emphasized {
  color: #b71f1c;
  font-size: 24px;
  line-height: 1.5;
  font-family: "ITC Avant Garde Gothic Std";
  font-weight: bold;
}

.text--white {
  color: white;
}

.text--red {
  color: #a6262a;
}

.text--emphasized {
  font-weight: bold;
}

.box--primary .paragraph {
  color: #fff;
}

.link {
  text-decoration: underline;
  font-size: 18px;
}

.link:hover {
  color: inherit;
}

.link--primary {
  color: #b71f1c;
}

.link--emphasized {
  font-family: "ITC Avant Garde Gothic Std";
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.uppercase-text {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.no-text-decoration {
  text-decoration: none;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.fs-50 {
  font-size: 50px;
}

.fs-51 {
  font-size: 51px;
}

.fs-52 {
  font-size: 52px;
}

.fs-53 {
  font-size: 53px;
}

.fs-54 {
  font-size: 54px;
}

.fs-55 {
  font-size: 55px;
}

.fs-56 {
  font-size: 56px;
}

.fs-57 {
  font-size: 57px;
}

.fs-58 {
  font-size: 58px;
}

.fs-59 {
  font-size: 59px;
}

.fs-60 {
  font-size: 60px;
}

.fs-61 {
  font-size: 61px;
}

.fs-62 {
  font-size: 62px;
}

.fs-63 {
  font-size: 63px;
}

.fs-64 {
  font-size: 64px;
}

.fs-65 {
  font-size: 65px;
}

.fs-66 {
  font-size: 66px;
}

.fs-67 {
  font-size: 67px;
}

.fs-68 {
  font-size: 68px;
}

.fs-69 {
  font-size: 69px;
}

.fs-70 {
  font-size: 70px;
}

.fs-71 {
  font-size: 71px;
}

.fs-72 {
  font-size: 72px;
}

.fs-73 {
  font-size: 73px;
}

.fs-74 {
  font-size: 74px;
}

.fs-75 {
  font-size: 75px;
}

.fs-76 {
  font-size: 76px;
}

.fs-77 {
  font-size: 77px;
}

.fs-78 {
  font-size: 78px;
}
