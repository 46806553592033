//VARIABLES
$ionicons-font-path: "https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/fonts" !default;
$ionicons-font-family: "Ionicons" !default;
$ionicons-version: "2.0.0" !default;
$ionicons-prefix: ion- !default;

$ionicon-var-android-contract: "\f37d";
$ionicon-var-android-expand: "\f386";

$ionicon-var-ios-arrow-left: "\f3d2";
$ionicon-var-ios-arrow-right: "\f3d3";

$ionicon-var-ios-play: "\f488";
$ionicon-var-ios-pause: "\f478";

//FONTS
@font-face {
  font-family: $ionicons-font-family;
  src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}");
  src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}#iefix")
      format("embedded-opentype"),
    url("#{$ionicons-font-path}/ionicons.ttf?v=#{$ionicons-version}")
      format("truetype"),
    url("#{$ionicons-font-path}/ionicons.woff?v=#{$ionicons-version}")
      format("woff"),
    url("#{$ionicons-font-path}/ionicons.svg?v=#{$ionicons-version}#Ionicons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

%ion {
  display: inline-block;
  font-family: $ionicons-font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//STYLING
$ig-screen-sm-min: 768px !default;
$ig-screen-xsm-min: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, 0.4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;

@mixin vendor-prefix($name, $value) {
  @each $vendor in ("-webkit-", "-moz-", "-ms-", "-o-", "") {
    #{$vendor}#{$name}: #{$value};
  }
}

.image-gallery {
  @include vendor-prefix("user-select", none);
  -webkit-tap-highlight-color: $ig-transparent;

  &.fullscreen-modal {
    background: $ig-black;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .image-gallery-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;

  &.fullscreen {
    background: $ig-black;

    .image-gallery-slide {
      background: $ig-black;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;
  span {
    position: static;
  }
  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 113px); // 100px + 8px for border + 5px for padding

    @media (max-width: $ig-screen-sm-min) {
      width: calc(100% - 84px); // 75px + 6px for border + 3px for padding
    }
  }
  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;

  &:hover {
    background-color: transparent !important;
  }

  &::before {
    @extend %ion;
    color: $ig-white;
    line-height: 0.7;
    text-shadow: 0 2px 2px lighten($ig-black, 10%);
    transition: color 0.2s ease-out;
  }

  &:hover::before {
    color: #b71f1c;

    @media (max-width: $ig-screen-sm-min) {
      color: #b71f1c;
    }
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;

  &::before {
    font-size: 2.7em;
    padding: 15px 20px;
    text-shadow: 0 1px 1px lighten($ig-black, 10%);

    @media (max-width: $ig-screen-sm-min) {
      font-size: 2.4em;
    }

    @media (max-width: $ig-screen-xsm-min) {
      font-size: 2em;
    }
  }

  &:hover::before {
    color: #b71f1c;
    transform: scale(1.1);

    @media (max-width: $ig-screen-sm-min) {
      transform: none;
    }
  }
}

.image-gallery-fullscreen-button {
  right: 0;

  &::before {
    content: $ionicon-var-android-expand;
  }

  &.active::before {
    content: $ionicon-var-android-contract;
  }

  &.active:hover::before {
    transform: scale(0.9);
  }
}

.image-gallery-play-button {
  left: 0;

  &::before {
    content: $ionicon-var-ios-play;
  }

  &.active::before {
    content: $ionicon-var-ios-pause;
  }
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  color: $ig-white;
  font-size: 5em;
  padding: 50px 15px;
  transform: translateY(25%); //-50%

  &[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
  }

  @media (max-width: $ig-screen-sm-min) {
    font-size: 3.4em;
    padding: 20px 15px;
  }

  @media (max-width: $ig-screen-xsm-min) {
    font-size: 2.4em;
    padding: 0 15px;
  }
}
.fullscreen-modal {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    top: 45%;
    transform: translateY(45%); //-50%
  }
}

.image-gallery-left-nav {
  left: 0;

  &::before {
    content: $ionicon-var-ios-arrow-left;
  }
}

.video-wrapper {
  iframe {
    height: 93%;
    width: 100%;
    margin: auto;
    margin-top: 1.5em;
  }
}

.image-gallery-right-nav {
  right: 0;

  &::before {
    content: $ionicon-var-ios-arrow-right;
  }
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.image-gallery-slide {
  position: absolute;
  background: $ig-white;
  width: 100%;
  left: 0;
  top: 0;

  &.center {
    position: relative;
  }
  .image-gallery-image {
    height: 100% !important; //important is used because it was not applying for the video's parent
  }

  img {
    margin: auto;
    width: auto;
    height: 25em;
    max-height: 25em;
  }

  .fullscreen {
    height: calc(100vh + 4.15em) !important;
    max-height: calc(100vh + 4.15em);
    top: 4em;
  }

  .image-gallery-description {
    background: $ig-background-black;
    bottom: 70px;
    color: $ig-white;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;

    @media (max-width: $ig-screen-sm-min) {
      bottom: 45px;
      font-size: 0.8em;
      padding: 8px 15px;
    }
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;

  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $ig-white;
    border-radius: 50%;
    box-shadow: 0 1px 0 lighten($ig-black, 10%);
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;

    @media (max-width: $ig-screen-sm-min) {
      margin: 0 3px;
      padding: 3px;
    }

    @media (max-width: $ig-screen-xsm-min) {
      padding: 2.7px;
    }

    &.active {
      background: $ig-white;
    }
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }
  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 108px; // 100px + 8px for border

    @media (max-width: $ig-screen-sm-min) {
      width: 81px; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        + .image-gallery-thumbnail {
          margin-left: 0;
        }
      }
    }
  }

  a:hover {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.left {
    margin-right: 5px;

    @media (max-width: $ig-screen-sm-min) {
      margin-right: 3px;
    }
  }

  &.right {
    margin-left: 5px;

    @media (max-width: $ig-screen-sm-min) {
      margin-left: 3px;
    }
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;

  @media (max-width: $ig-screen-sm-min) {
    padding: 3px 0;
  }

  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform 0.45s ease-out;
    white-space: nowrap;
  }
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 70px;

  @media (max-width: $ig-screen-sm-min) {
    border: 3px solid transparent;
    width: 50px;
  }

  + .image-gallery-thumbnail {
    margin-left: 2px;
  }

  .image-gallery-thumbnail-inner {
    position: relative;
  }

  img {
    vertical-align: middle;
    height: 5.5em;
    width: auto; //100%
    margin: auto;
  }

  &.active {
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media (max-width: $ig-screen-sm-min) {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;

  @media (max-width: $ig-screen-sm-min) {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: $ig-background-black;
  color: $ig-white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  @media (max-width: $ig-screen-sm-min) {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.image-gallery-center {
  position: relative;
  text-align: center;
}