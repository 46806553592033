/*!
 * Copyright 2002 - 2013 Webdetails, a Pentaho company.  All rights reserved.
 *
 * This software was developed by Webdetails and is provided under the terms
 * of the Mozilla Public License, Version 2.0, or any later version. You may not use
 * this file except in compliance with the license. If you need a copy of the license,
 * please go to  http://mozilla.org/MPL/2.0/. The Initial Developer is Webdetails.
 *
 * Software distributed under the Mozilla Public License is distributed on an "AS IS"
 * basis, WITHOUT WARRANTY OF ANY KIND, either express or  implied. Please refer to
 * the license for the specific language governing your rights and limitations.
 */

.tipsy {
  pointer-events: none;
  padding: 5px;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  z-index: 100000;
  opacity: 1 !important;
}

.tipsy-inner {
  padding: 5px 8px 4px 8px;
  background-color: black;
  color: white;
  max-width: 200px;
  text-align: center;
}

.tipsy-inner {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.tipsy-arrow {
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  border: 5px dashed #3f3f3f;
}

.tipsy-n .tipsy-arrow {
  top: 0px;
  left: 50%;
  margin-left: -5px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-nw .tipsy-arrow {
  top: 0;
  left: 10px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-ne .tipsy-arrow {
  top: 0;
  right: 10px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-s .tipsy-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-sw .tipsy-arrow {
  bottom: 0;
  left: 10px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
  bottom: 0;
  right: 10px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
}

.tipsy-e .tipsy-arrow {
  right: 0;
  top: 50%;
  margin-top: -5px;
  border-left-style: solid;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.tipsy-w .tipsy-arrow {
  left: 0;
  top: 50%;
  margin-top: -5px;
  border-right-style: solid;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.ccc-tt {
  overflow: hidden;
}

.ccc-tt-ds {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  line-height: 1.5em;
}

.ccc-tt-ds tr {
  vertical-align: baseline;
  background-color: #3f3f3f;
}

.ccc-tt-ds tr td {
  padding: 0;
}

.ccc-tt-ds .ccc-tt-dimLabel {
  font-weight: 600;
  color: darkgray;
  padding-right: 0.3em;
  white-space: nowrap;
}

.ccc-tt-ds .ccc-tt-dimAgg-sum .ccc-tt-dimLabel span:before {
  content: "\03a3\00a0";
  font-weight: normal;
  font-style: normal;
  font-size: 1.1em;
}

.ccc-tt-ds .ccc-tt-dimAgg-list .ccc-tt-dimLabel span:before {
  content: "\22c3\00a0";
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;
}

.ccc-tt-ds .ccc-tt-datumCount span:before {
  content: "\0023\00a0";
  color: darkgray;
  font-weight: normal;
  font-size: 1.1em;
}

.ccc-tt-ds .ccc-tt-trendLabel {
  font-size: 1.2em;
  font-weight: bold;
}

.ccc-tt-ds .ccc-tt-trendLabel td {
  padding-bottom: 0.2em;
}

.ccc-tt-ds .ccc-tt-dimRoles {
  text-align: center;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.ccc-tt-ds .ccc-tt-roleLabel {
  display: none;
}

.ccc-tt-ds.ccc-tt-chartOrient-v .ccc-tt-role-x .ccc-tt-roleIcon:after, .ccc-tt-ds.ccc-tt-chartOrient-h .ccc-tt-role-y .ccc-tt-roleIcon:after, .ccc-tt-ds.ccc-tt-chartOrient-h .ccc-tt-role-value .ccc-tt-roleIcon:after {
  content: "\2194";
  font-size: 1.2em;
}

.ccc-tt-ds.ccc-tt-chartOrient-v .ccc-tt-role-y .ccc-tt-roleIcon:after, .ccc-tt-ds.ccc-tt-chartOrient-h .ccc-tt-role-x .ccc-tt-roleIcon:after, .ccc-tt-ds.ccc-tt-chartOrient-v .ccc-tt-role-value .ccc-tt-roleIcon:after {
  content: "\2195";
  font-size: 1.2em;
}

.ccc-tt-ds.ccc-tt-plot-pie .ccc-tt-role-value .ccc-tt-roleIcon:after, .ccc-tt-ds.ccc-tt-plot-sunburst .ccc-tt-role-size .ccc-tt-roleIcon:after {
  content: "\2220";
  font-size: 1.5em;
}

.ccc-tt-ds .ccc-tt-role-multiChart .ccc-tt-roleIcon:after {
  content: "\229e";
  font-size: 1.2em;
}

.ccc-tt-ds .ccc-tt-dimValue {
  padding-left: 0.3em;
  text-align: left;
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-valuePct {
  color: darkgray;
  font-size: 0.9em;
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-valuePct:before {
  padding-left: 0.7em;
  content: '(';
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-valuePct:after {
  content: ')';
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-interp {
  color: darkgray;
  font-size: 0.9em;
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-interp:before {
  padding-left: 0.7em;
  content: '(';
}

.ccc-tt-ds .ccc-tt-dimValue .ccc-tt-interp:after {
  content: ')';
}

