.react-filters {
  font-size: 12px;
}

.react-filters-card {
  float: left;
  width: 20%;
  padding-right: 2.2857em;
}

.react-filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 0;

  color: $text-color;
  font-weight: bold;
  cursor: pointer;
}

.react-filter-component .react-filter-input,
.data-search-input,
.data-search-list {
  font-size: 12px !important;
  background-color: #ffffff !important;
  border-radius: 4px;

  &:hover {
    border-color: $primary-color;
  }
}

.data-search-list {
  margin-top: 5px !important;

  li .trim > div {
    display: flex;
    align-items: center;
  }
}

.data-search {
  position: absolute;
  left: 33%;
  width: 34%;
  z-index: 3;

  div[class*="InputIcon-right"] {
    top: 10px;
    svg {
      fill: $primary-color;
    }
  }

  div[class*="InputIcon-right-clear"] {
    top: 9px;

    svg {
      fill: #595959;
    }
  }
}

.react-single-list-component,
.react-multi-list-component {
  ul {
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    height: 2em !important;

    label {
      align-items: center;
    }

    input + label::before {
      position: unset;
      flex-shrink: 0;
      color: $primary-color;
      width: 12px;
      height: 12px;
      margin-top: 0;
    }

    input + label > span > span {
      line-height: 1;
    }
  }

  li:hover {
    input + label {
      color: $primary-color;
      &:before {
        border-color: $primary-color;
      }
    }
  }

  .active {
    label,
    input:checked:active + label,
    input:checked:focus + label {
      color: $primary-color;
    }

    input:checked + label::before {
      border-color: $primary-color;
      background-color: #ffffff;
    }
  }
}

.react-single-list-component {
  input + label::after {
    background-color: $primary-color;
  }
  input + label::after {
    width: 8px;
    height: 8px;
    margin: 0 0 auto;
  }
}

.react-single-list-component .active {
  input + label::after {
    background-color: $primary-color;
  }
}
.react-multi-list-component .active {
  input + label::after {
    border-color: $primary-color;
  }
  input + label::after {
    top: 47%;
    left: 2%;
    width: 6px;
    height: 3px;
  }
}

.react-breadcrumbs {
  font-size: 12px;
  a:not(:last-of-type) {
    background-color: #fffa03;
  }
}

.react-slider-component {
  .react-slider-label {
    top: -35px;

    font-size: 12px;
    color: #2a2a2a;
  }

  .rheostat-handle {
    margin-left: -17px !important;
  }

  .rheostat-background {
    border-radius: 2px;
  }

  .rheostat button {
    top: -1.35em !important;

    height: 3em;

    background-color: $primary-color;
    border: 1px solid #ffffff;

    transform: scale(0.45);
    transition-duration: 150ms;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      box-shadow: 0 0 0 15px rgba(244, 40, 40, 0.15);
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 18px rgba(244, 40, 40, 0.3);
    }
  }

  .rheostat-progress {
    background-color: $primary-color !important;
  }
}