#feedback-js {
  background-color: rgba(0, 0, 0, 0) !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  z-index: 100;
}
#feedback-js div.fb-form-container {
  min-height: 636px;
  min-width: 360px;
  max-width: 360px;
  z-index: 30;
}
#feedback-js div.fb-form-container form,
#feedback-js div.fb-form-container .status {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  align-content: stretch;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 2;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  background-color: #fafafa;
}
#feedback-js div.fb-form-container form .fb-header {
  background-color: #b91d1a;
  box-sizing: border-box;
  color: #fff;
  height: 56px;
}
#feedback-js div.fb-form-container form .fb-header h1 {
  color: #fff;
  float: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-stretch: normal;
  font-size: 20px;
  line-height: 56px;
  padding: 0px 16px;
  margin: 0px;
}
#feedback-js div.fb-form-container form textarea {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 196px;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  color: #212121;
  flex-grow: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 16px;
  line-height: normal;
  max-width: 100%;
  outline: none;
  padding: 18px 16px 0px;
  resize: none;
  width: 100%;
}
#feedback-js div.fb-form-container form textarea::-webkit-input-placeholder,
#feedback-js div.fb-form-container form textarea:-ms-input-placeholder,
#feedback-js div.fb-form-container form textarea::-moz-placeholder {
  color: #bdbdbd;
}
#feedback-js div.fb-form-container form .fb-checkbox label {
  cursor: pointer;
  align-items: center;
  display: -webkit-flex;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  justify-content: flex-start;
  padding: 8px 16px 0px 8px;
  -webkit-tap-highlight-color: transparent;
  width: auto;
}
#feedback-js div.fb-form-container form .fb-checkbox input {
  display: none;
}
#feedback-js div.fb-form-container form .fb-checkbox div {
  background-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#feedback-js div.fb-form-container form .fb-screenshot {
  align-items: center;
  background-color: #eeeeee;
  color: #9e9e9e;
  display: -webkit-flex;
  height: 192px;
  justify-content: center;
  margin: 8px 0px 12px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
#feedback-js div.fb-form-container form .fb-screenshot canvas {
  width: auto !important;
  max-height: 100%;
}
#feedback-js div.fb-form-container form small {
  color: #757575;
  display: block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.05px;
  padding: 0px 16px 12px;
}
#feedback-js div.fb-form-container form .fb-actions {
  align-items: stretch;
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  height: 56px;
  display: -webkit-flex;
  flex-direction: row-reverse;
}
#feedback-js div.fb-form-container .status {
  height: 56px;
  padding-left: 8px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
#feedback-js canvas.draw-area {
  position: fixed;
  top: 0;
  left: 0;
  display: contents !important;//for testing
}
#feedback-js canvas.draw-area.active {
  cursor: crosshair;
}
#feedback-js div.fb-draw-options {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 30;
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-direction: row;
  height: 56px;
  min-width: 232px;
}
#feedback-js div.fb-draw-options .dragger {
  height: 100%;
  background-color: #fff;
  cursor: move;
  cursor: -webkit-grab;
}
#feedback-js div.mat-button {
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: -webkit-inline-flex;
  min-height: 48px;
  padding: 0px 8px;
}
#feedback-js div.mat-button.primary {
  color: #b91d1a;
}
#feedback-js div.mat-button button {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  background-color: transparent;
  border-color: transparent;
  border-radius: 2px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  margin: 0px;
  min-width: 64px;
  opacity: 1;
  outline: none;
  padding: 0px 8px;
  text-transform: uppercase;
}
#feedback-js div.mat-button button:hover {
  background-color: #eeeeee;
}

#feedback-js {
  div.highlight {
    opacity: 0.4;
  }

  .fb-draw-options {
    .dragger {
      display: none;
    }
    button {
      padding: 0.5em;
      margin: 10px;
    }
  }
}

.feedback-bottom-right {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 21;
  padding: 15px 20px;
  font-weight: bold;
  font-size: 13px;
  color: #fff;
  text-align: center;
}
