.premium {
  background-image: url("~cost-model-03.svg");
  background-size: 59%;
  display: block;
  height: 1.18rem;
  width: 1.18rem;
  left: 40%;
  top: 0.06rem;
  z-index: 1;
}

.mixed {
  background-image: url("~cost-model-04.svg");
  background-size: 59%;
  display: block;
  height: 1.18rem;
  width: 1.18rem;
  left: 40%;
  top: 0.06rem;
  z-index: 1;
}

.unknown {
  background-image: url("~question-mark-01.svg");
  background-size: 59%;
  display: block;
  height: 1.18rem;
  width: 1.18rem;
  left: 40%;
  z-index: 1;
}

@media print {
  .premium {
    background-image: url("~cost-model-03.svg");
    background-size: 59%;
    display: block;
    height: 1.18rem;
    width: 1.18rem;
    left: 40%;
    top: 0.06rem;
  }

  .mixed {
    background-image: url("~cost-model-04.svg");
    background-size: 59%;
    display: block;
    height: 1.18rem;
    width: 1.18rem;
    left: 40%;
    top: 0.06rem;
  }

  .unknown {
    background-image: url("~question-mark-01.svg");
    background-size: 59%;
    display: block;
    height: 1.18rem;
    width: 1.18rem;
    left: 40%;
    top: 0.06rem;
  }
}

th.rm_col_cm.sorting {
  font-size: 0;

  &:after {
    display: none;
  }
}

td.column1.rm_col_cm {
  overflow: visible;
}

td.column2.rm_col_cm {
  overflow: visible;

  .premium {
    left: 40%;
  }
}

.premiumSearch {
  background-image: url("~cost-model-03.svg");
  display: block;
  height: 1.25rem;
  width: 1.25rem;
}

.mixedSearch {
  background-image: url("~cost-model-04.svg");
  display: block;
  height: 1.25rem;
  width: 1.25rem;
}

.gameSearch {
  background-image: url("~gameSearch.svg");
  background-size: 100%;
  display: block;
  height: 2rem;
  width: 2rem;
}

.cabinetSearch {
  background-image: url("~cabinetSearch.svg");
  background-size: 100%;
  display: block;
  height: 2rem;
  width: 2rem;
}