html,
body,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
button,
datalist,
select,
textarea,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
output,
progress,
ruby,
section,
summary,
time,
audio,
video,
:before,
:after {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-color: $text-color;
  border-style: solid;
  box-sizing: border-box;
  float: none;
  font: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: inherit;
  vertical-align: baseline;
  white-space: inherit;
}

// aside,
// audio,
// canvas,
// details,
// dialog,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// main,
// marquee,
// menu,
// menuitem,
// meter,
// nav,
// output,
// picture,
// progress,
// rp,
// rt,
// ruby,
// section,
// summary,
// track,
// video,
// wbr {
//   display: block;
// }

html,
body {
  height: 100%;
}

html,
button,
datalist,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased; /* font-smoothing: antialiased; text-rendering: optimizeLegibility;*/
}

html {
  overflow-y: scroll;
}

body {
  background: #fff; /*-moz-font-feature-settings: "liga=1, dlig=1"; -ms-font-feature-settings: "liga", "dlig"; -webkit-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig";*/
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; /* text-size-adjust: 100%;*/
  word-wrap: break-word;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td,
textarea,
li {
  vertical-align: top;
}

th {
  font-weight: bold;
  text-align: left;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

pre,
code,
kbd,
samp,
button,
datalist,
select,
textarea {
  font-size: 1em;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
}

strong,
b,
legend,
optgroup {
  font-weight: bold;
}

em,
i,
dfn,
span[lang] {
  font-style: italic;
}

u {
  text-decoration: none;
}

img,
video,
object,
iframe {
  max-width: 100%;
}

img,
video {
  height: auto;
  width: auto;
}

video {
  width: 100%;
}

ol,
ul {
  list-style-position: outside;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button,
datalist,
input,
select,
textarea,
legend,
label,
.label {
  line-height: normal;
}

button,
datalist,
select,
textarea {
  border-radius: 0;
  border-width: 0;
  font-weight: normal;
  margin: 0;
}

button,
input[type="checkbox"],
input[type="file"],
input[type="radio"],
input[type="reset"],
label,
select,
a {
  cursor: pointer;
}

button {
  -webkit-appearance: button;
  background-image: none;
  display: inline-block;
  overflow: visible;
  vertical-align: top;
  width: auto;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border-width: 0;
  padding: 0;
}

datalist,
input,
select,
textarea,
fieldset,
legend,
table {
  width: 100%;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* remove mobile input shadow */
input[type="search"] {
  padding-right: 0;
  -webkit-appearance: textfield;
}

input::-webkit-search-decoration /*, input::-webkit-search-cancel-button*/
 {
  display: none;
}

select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[type="checkbox"],
[type="radio"],
[type="checkbox"]:focus,
[type="radio"]:focus {
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  padding: 0;
  width: auto;
  outline: none !important;
}

:focus,
:active {
  /*position: relative; top: 1px;*/
  transition: none;
}

:hover,
:active {
  outline: none;
}
