/* Search Box */
.search-input {
  padding-left: 36px !important;
  font-size: 15px !important;
  border-radius: 25px !important;
  background-color: #F0F0F0 !important;

}
.search-input:focus {
  border-color: #b71f1c !important;
  box-shadow: 2px !important;
  background-color: #F0F0F0 !important;
}
.search-input:active {
  border-color: #b71f1c !important;
  box-shadow: 2px !important;
}
.input-group{
  box-shadow: none !important;
}
/* Search Icon */
.search-field .search-button {
  background-image: url(https://operators.reelmetrics.com/packs/media/images/search-e5197a2d.svg);
}
.search-icon {
  fill: #7c8188  !important;
}
.search-icon:hover {
  fill: #323232 !important;
}
/* Search Fonts */
.noSearchSpace{
  padding: 0 !important;
  margin: 0 !important;
}
.searchResDetails{
  font-style: oblique !important;
  font-size: 11px !important;
  overflow: hidden !important;
}
.supplierSearchRes{
  color: #606060 !important;
  text-decoration: none !important;
  text-align: left !important;
}
.gameSearchRes{
  color: #606060 !important;
}
.cabinetSearchRes{
  color: #606060 !important;
}
.titleSearchRes{
  font-size: 12px !important;
  text-align: left !important;
  padding-left: 0 !important;
}
/* Suggestion List */
.divider{
  color: black !important;
  background-color: black !important;
}
.search-list{
  max-height: 381px !important;
  z-index: 6 !important;
}
.search-list ul{
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  z-index: 6 !important;
}
.css-g7n141-SuggestionWrapper{
  z-index: 6 !important;
}
.search-list li{
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  text-align: left !important;
  z-index: 6 !important;
}
.search-list li:nth-child(even){
  background-color: $all-colors !important;
}
.search-list li:nth-child(odd){
  background-color: #e8e8e8 !important;
}
.search-list li:nth-child(even):hover{
  background-color: $highlight-color !important;
}
.search-list li:nth-child(odd):hover{
  background-color: $highlight-color !important;
}
.search-list li:hover{
  opacity: 0.9 !important;
  transition: all 80ms ease-in !important;
  font-weight: 600 !important;
}

.css-1eqf1yw-TagsContainer{
 display: none !important;
} 
.es4ry370{
  display: none !important;
}