.member-req-form{
  /* background-color: #FFF;
  width: 73%; */
  margin: 20px auto;
}

.member-req-form input{
  /* background-color: #f0f0f0; */
  background-color: #ededed;
  border-top: 3px solid lightgray;
}

.member-req-form h1 {}

.member-req-form > p {}

.member-req-form .form-input-icon {
  float: right;
  top: -25px;
  right: 8px;
}

.member-req-form form {
  margin-top: 40px;
}

.member-req-form button {
  margin-top: 30px;
}

.confirm h1,
.confirm p {
  text-align: center;
}

.confirm {
  margin-top: 25px;
  margin-bottom: 25px;
}

.g-recaptcha {
  margin-top: 36px;
}

#new_access_request label {
  display: none;
}

#new_access_request .input {
  margin-top: 20px;
  /* margin-bottom:30px; */
}

#new_access_request .error {
  color: red;
}

#new_access_request .error-bar {
  padding: 28px;
  background-color: #f2dede;
}

.custom-recaptcha-error {
  color: red;
}
/**Progress bar styling**/
.request-progress-bar{
  /*width: 80%;*/
  z-index: 1;
  margin: auto;
  margin-bottom: 53px;
}

.request-progress-bar-items {
  overflow: hidden;
}

.request-progress-bar-items li {
  float: left;
  width: 25%;
  list-style-type: none;
  text-align: center;
}

.request-progress-bar-items li:before {
  // content: "X";
  width: 40px;
  height: 40px;
  border: 2px solid #bebebe;
  clear: both;
  border-radius: 50%;
  display: block;
  margin: 7px auto;
  line-height: 3;
  color: #bebebe;
  font-weight: bolder;
  background-color: #FFF;
  font-family: "Font Awesome 5 Free";
}

.request-progress-bar-items li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: black;
  top: 26px;
  z-index: -1;
  left: -54%;
  background: #979797;
}

.request-progress-bar-items li:first-of-type:after {
  content: none;
}

.request-progress-bar-items li.complete:after {
  background: #3aac5d;
}

.request-progress-bar-items li.complete:before {
  border-color: #3aac5d;
  background: #3aac5d;
  color: white;
}

.request-progress-bar-items li.current:before {
  background-color: #bebebe;
  color: white;
}

.request-progress-bar-items li:first-of-type:before {
  content: "\f15c";
}

.request-progress-bar-items li:nth-child(2):before {
  content: "\f0e0";
}

.request-progress-bar-items li:nth-child(3):before {
  content: "\f00c";
}

.request-progress-bar-items li:last-child:before {
  content: "\f52b";
}

.password-tooltip{
  margin-top: -2.6em;
  margin-right: 6px;
}
