.help-content h2,
.help-content h3 {
  color: $primary-color;
}
.shift-content {
  position: relative;
  z-index: 5;
}
.help-trigger {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.help-trigger:hover,
.help-trigger:active {
  background-color: darken($primary-color, 10%);
}

.tabs--tutorial-video-hack {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.tabs--help .tabs-trigger {
  display: inline-block;
  padding: 1em 1.4285714286em;
  text-decoration: none;
  font-weight: bold;
}

.help-trigger {
  display: block;
  position: fixed;
  top: 0;
  right: 4em;
  width: 4em;
  height: 4em;
  z-index: 5;
  overflow: visible !important;
}

.help-trigger:hover,
.help-trigger:active {
  background-color: darken($primary-color, 10%);
  color: #fff;
}

.help-msg,
.help-faux {
  display: inline-block;
}

.help-faux {
  margin-top: 8px;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  text-indent: -999em;
  background-image: url("~help.png");
  background-size: contain;
  background-position: center 8px;
}

.help-trigger.active .help-faux {
  background-image: url("~close.svg");
  background-position: center 2px;
}

.help-trigger.active .js-help-close {
  z-index: 5;
}

.help-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 4;
  background-color: #dddddd;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.help-content.active {
  height: 100vh;
  transform: translateX(0);
}

.help-content-parts {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.help-content-part-alt {
  display: table-caption;
  background-color: #fff;
  padding: 1em;
}

.grid-item--tabs-help {
  width: 33.333%;
}

.help-tutorial {
  video {
    width: 90em;
    height: auto;
  }
}

.limit--help {
  max-height: 55em;
  max-height: 100vh;
  margin-bottom: $paragraph-margin;
}

.limit--search {
  height: 41em;
}

.help-msg,
.help-close {
  display: none;
}

.help-content-col.scoped {
  padding-right: 2em;
}

.help-content-col {
  float: left;
  width: 70%;
}

.help-content-col-alt {
  float: right;
  width: 30%;
}

.help-home img {
  height: 30px;
}

.help-content-inner tr:nth-child(even) {
  background-color: #fff;
}

.help-content-inner tr:nth-child(odd) {
  background-color: #f3f3f3;
}

.help-content-inner table.no-striping tr {
  background-color: transparent;
}

@include respond-min-width(43em) {
  .help-msg {
    display: inline-block;
    margin-right: $page-padding;
    vertical-align: -0.2em;
    font-weight: normal;
  }
  .help-faux {
    float: right;
    text-align: left;
    margin-top: 0;
  }
  .help-faux:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 1.875em solid transparent;
    border-right: 1.875em solid transparent;
    border-top: 0.625em solid $primary-color;
    display: block;
    position: absolute;
    top: 2em;
    left: -0.8em;
    transition: 0.1s;
  }
  .help-trigger:hover .help-faux:after,
  .help-trigger:active .help-faux:after {
    border-top-color: darken($primary-color, 10%);
  }
  .help-trigger {
    position: relative;
    top: auto;
    right: auto;
    width: auto;
    height: auto;
    padding: 0 0.5em;
    text-align: right;
  }
  .help-page {
    padding-left: $page-padding;
    padding-right: $page-padding;
  }
  .help-content {
    position: static;
    transform: translateX(0);
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
  }
  .help-container.active .help-content.active {
    max-height: 100vh;
    max-height: calc(100vh - 25px);
  }
  .help-content.active {
    max-height: 100vh;
    max-height: calc(100vh - 98px);
    border-bottom: 3px solid $primary-color;
  }
  .help-close {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    padding: 0.25em 1em;
    font-size: 0.875em;
    margin-left: -15px;
  }
  .help-content.active .help-close {
    bottom: -3px;
  }
  .limit--help {
    margin-bottom: 0;
  }
  .help-container.active {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 21;
  }
  .help-content-part,
  .help-content-part-alt,
  .help-content-parts {
    display: block;
    margin: 0;
  }
  .help-content-part {
    float: right;
    width: 71%;
  }
  .help-content-part-alt {
    float: left;
    width: 24%;
    padding-right: $paragraph-margin;
  }

  .grid-item--tabs-help {
    width: auto;
  }
}

@media print and (color) {
  .help-trigger {
    display: none !important;
  }
}
