@font-face {
  font-family: "Roboto";
  src: font-url("../../assets/fonts/Roboto-Regular.eot");
  src: font-url("../../assets/fonts/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    font-url("../../assets/fonts/Roboto-Regular.woff") format("woff"),
    font-url("../../assets/fonts/Roboto-Regular.ttf") format("truetype"),
    font-url("../../assets/fonts/Roboto-Regular.svg#robotoregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: font-url("../../assets/fonts/Roboto-RegularItalic.eot");
  src: font-url("../../assets/fonts/Roboto-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    font-url("../../assets/fonts/Roboto-RegularItalic.woff") format("woff"),
    font-url("../../assets/fonts/Roboto-RegularItalic.ttf") format("truetype"),
    font-url("../../assets/fonts/Roboto-RegularItalic.svg#robotoitalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: font-url("../../assets/fonts/Roboto-Bold.eot");
  src: font-url("../../assets/fonts/Roboto-Bold.eot?#iefix")
      format("embedded-opentype"),
    font-url("../../assets/fonts/Roboto-Bold.woff") format("woff"),
    font-url("../../assets/fonts/Roboto-Bold.ttf") format("truetype"),
    font-url("../../assets/fonts/Roboto-Bold.svg#robotobold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Book";
  src: url("../../assets/fonts/ITCAvantGardeStd-Bk.eot");
  src: url("../../assets/fonts/ITCAvantGardeStd-Bk.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/ITCAvantGardeStd-Bk.woff2") format("woff2"),
    url("../../assets/fonts/ITCAvantGardeStd-Bk.woff") format("woff"),
    url("../../assets/fonts/ITCAvantGardeStd-Bk.ttf") format("truetype"),
    url("../../assets/fonts/ITCAvantGardeStd-Bk.svg#ITCAvantGardeStd-Bk")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std";
  src: url("../../assets/fonts/ITCAvantGardeStd-Bold.eot");
  src: url("../../assets/fonts/ITCAvantGardeStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/ITCAvantGardeStd-Bold.woff2") format("woff2"),
    url("../../assets/fonts/ITCAvantGardeStd-Bold.woff") format("woff"),
    url("../../assets/fonts/ITCAvantGardeStd-Bold.ttf") format("truetype"),
    url("../../assets/fonts/ITCAvantGardeStd-Bold.svg#ITCAvantGardeStd-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi";
  src: url("../../assets/fonts/ITCAvantGardeStd-Demi.eot");
  src: url("../../assets/fonts/ITCAvantGardeStd-Demi.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/ITCAvantGardeStd-Demi.woff2") format("woff2"),
    url("../../assets/fonts/ITCAvantGardeStd-Demi.woff") format("woff"),
    url("../../assets/fonts/ITCAvantGardeStd-Demi.ttf") format("truetype"),
    url("../../assets/fonts/ITCAvantGardeStd-Demi.svg#ITCAvantGardeStd-Demi")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std";
  src: url("../../assets/fonts/ITCAvantGardeStd-Md.eot");
  src: url("../../assets/fonts/ITCAvantGardeStd-Md.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/ITCAvantGardeStd-Md.woff2") format("woff2"),
    url("../../assets/fonts/ITCAvantGardeStd-Md.woff") format("woff"),
    url("../../assets/fonts/ITCAvantGardeStd-Md.ttf") format("truetype"),
    url("../../assets/fonts/ITCAvantGardeStd-Md.svg#ITCAvantGardeStd-Md")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Extra";
  src: url("../../assets/fonts/ITCAvantGardeStd-XLt.eot");
  src: url("../../assets/fonts/ITCAvantGardeStd-XLt.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/ITCAvantGardeStd-XLt.woff2") format("woff2"),
    url("../../assets/fonts/ITCAvantGardeStd-XLt.woff") format("woff"),
    url("../../assets/fonts/ITCAvantGardeStd-XLt.ttf") format("truetype"),
    url("../../assets/fonts/ITCAvantGardeStd-XLt.svg#ITCAvantGardeStd-Xlt")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

