.react-compact {
  min-height: 30em;
  height: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.react-layout2-card {
  min-height: 25.5em;
  height: 100%;
}

.react-layout2-side-menu {
  min-height: 69.35em; //position: absolut would do the trick but it is breakin bootstrap
  height: 100%;

  .menu-item {
    list-style: none;
    border-bottom: 1px solid #ddd;
    padding: 0.65em;
    color: #2a2a2a;
    font-weight: bold;
  }
}

.select-time-filter {
  z-index: 3;
}

.library-performance-table {
  margin-top: 20px;
}

.react-card-arrow {
  width: 0;
  height: 0;
  border: 1.45em solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  position: absolute;
  top: 29.25em; //depends on the card height (crad height - 0.75em)
  left: 16%;
}

.generic_table table {
  margin-top: 0;
}

.show-grid {
  padding-top: 30px;
}

.image-gallery-slide-wrapper {
  border: solid #ccc 1px;
}

button.image-gallery-thumbnail {
  width: auto;
  background-color: #ffffff;

  :hover {
    background-color: #ffffff !important;
  }
}

svg.image-gallery-svg {
  display: none;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  transform: translateY(120%);
}

.fullscreen-modal .image-gallery-left-nav,
.fullscreen-modal .image-gallery-right-nav {
  top: 0;
  transform: translateY(120%);
}

.cabinet-description-area {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background-color: #ffffff;

  .cabinet-description-col {
    flex: 0 1 calc(50% - 13px);

    table {
      margin: 0;

      th,
      td {
        border: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .cabinet-description-area {
    flex-direction: row;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    transform: translateY(45%);
  }

  .fullscreen-modal .image-gallery-left-nav,
  .fullscreen-modal .image-gallery-right-nav {
    top: 0;
    transform: translateY(45%);
  }
}

//IE Only
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
