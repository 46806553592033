.reelhot-index {
  .dx-pager {
    visibility: visible !important;
  }
  .tabpanel-item {
    padding: 25px;
  }
  .accordion-item {
    padding: 25px;
  }
  .dx-datagrid-table .cell-progress-bar {
    color: white;
    display: inline-flex;
    text-align: center;
  }
}

.reelhot-index-info-icon {
  margin-top: 20px;
}

#core-leaders-table,
#premium-leaders-table {
  margin-bottom: 20px;
}
