.list, %list {
  list-style: none;
  list-style-image: none;
  padding-left: 0;
}

.accessibility, .js .js-accessibility {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.accessibility:focus, .js .js-accessibility:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: fixed;
  width: auto;
}

.group, .block, .wrap, .paragraph {
  display: block;
}

.group:before, .group:after {
  content: " ";
  display: table;
}

.group:after {
  clear: both;
}

%group:before, %group:after {
  content: " ";
  display: table;
}

%group:after {
  clear: both;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}

.wrap {
  overflow: hidden;
}

.link-alt:hover, .link-alt:active {
  background-color: transparent;
}

.link-alt:active {
  box-shadow: none;
}

.default {
  font-size: 1em;
}

.accent {
  font-weight: bold;
}

.special {
  font-style: italic;
}

.underline, .link-underline:hover, .link-underline:active {
  text-decoration: underline;
}

.row {
  white-space: nowrap;
}

.center {
  text-align: center;
}

.full {
  width: 100%;
}

.fill {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.number::-webkit-outer-spin-button, .number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.video {
  background-color: #222;
  display: block;
  padding: 0 0 56.25%;
  position: relative;
}

.video iframe, .video object, .video embed, .video video {
  height: 100% !important;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.fb_iframe_widget, .fb_iframe_widget span {
  display: block !important;
}

.fb_iframe_widget iframe {
  margin: 0;
  max-width: none;
}
