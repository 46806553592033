.carousel {
  position: relative;
  display: block;

  figure {
    max-width: 100%;
    height: auto;
    background-size: cover;
    position: relative;
  }

  .carousel-video {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: 2px solid #f0f0f0;
  }

  .carousel-inner > .item {
    display: none;
    position: relative;
    -webkit-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left;
  }

  .carousel-inner > .active,
  .carousel-inner > .next,
  .carousel-inner > .prev {
    display: block;
  }
  .carousel-inner > .active {
    left: 0;
  }
  .carousel-inner > .next,
  .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .carousel-inner > .next {
    left: 100%;
  }
  .carousel-inner > .prev {
    left: -100%;
  }
  .carousel-inner > .next.left,
  .carousel-inner > .prev.right {
    left: 0;
  }
  .carousel-inner > .active.left {
    left: -100%;
  }
  .carousel-inner > .active.right {
    left: 100%;
  }

  .carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel-control {
    position: absolute;
    text-align: center;
    fill: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    -moz-transform: translate(0);
    -o-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);
    will-change: transform;
    &.left,
    &.right {
      height: 2.25em;
      width: 2.25em;
    }

    &.left {
      -moz-transform: translate(-100%);
      -o-transform: translate(-100%);
      -webkit-transform: translate(-100%);
      transform: translate(-100%);
    }
    &.right {
      -moz-transform: translate(100%);
      -o-transform: translate(100%);
      -webkit-transform: translate(100%);
      transform: translate(100%);
      left: auto;
      right: 0;
    }
  }

  &:hover {
    .carousel-control {
      &.left,
      &.right {
        -moz-transform: translate(0);
        -o-transform: translate(0);
        -webkit-transform: translate(0);
        transform: translate(0);
        background-color: rgba(0, 0, 0, 0);
      }
      &.left {
        background-image: url("~carousel-control-left.svg");
        background-size: contain;
        transform: rotate(180deg);
      }
      &.right {
        background-image: url("~carousel-control-right.svg");
        background-size: contain;
      }
    }
  }

  .carousel-indicators {
    position: absolute;
    bottom: 2.3rem;
    left: 50%;
    z-index: 5;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    text-align: center;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 1px;
      text-indent: -999px;
      border-radius: 10px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #b71f1c;
    }

    .active {
      margin: 0;
      width: 12px;
      height: 12px;
      background-color: #b71f1c;
      border: 1px solid #b71f1c;
    }
  }
}
