.rm-news-card {
  display: flex;
  flex-direction: column;
}

.news_wrapper {
  max-width: 100%;

  .index_sheet {
    padding-bottom: 0;
  }

  .sheet {
    max-height: 100%;

    .content_wrapper {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 2.2857em;
      overflow: auto;
    }

    .index_date_wrapper {
      width: 14.95%;
      float: left;
      margin-top: 1.8em;
      margin-left: -1.14em;
      text-align: center;
    }

    .index_details_wrapper {
      width: 85%;
      float: right;

      .summary {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        height: 1.3em;
        white-space: nowrap;
      }
    }
  }

  .cols {
    height: 100%;
  }

  .show_details_wrapper {
    padding-right: 1.75em;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    min-height: 100%;

    h2 {
      top: 6%;
      left: 16%;
      width: 95%;
    }

    .bookmark_date {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
      background-color: #b71f1c;
      color: #fff;
      font-weight: bold;
      width: 12.5%;
      height: 10%;
      padding: 35px;
      border-radius: 0 33% 0 33%;
    }

    .bookmark_date > div > p {
      margin: 0 !important;
    }

    .back_link {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border: none;
      padding: 12px;
      width: 120px;
      text-align: center;
      -webkit-transition-duration: 0.4s; /* Safari */
      transition-duration: 0.6s;
      text-decoration: underline;
      overflow: hidden;
      cursor: pointer;
      font-size: 16px;
      margin: 0;
      color: #b71f1c;
    }

    .back_link:hover {
      background: #b71f1c;
      box-shadow: 0px 2px 10px 5px rgb(130, 130, 130);
      color: #fff;
      border-radius: 0 33px 0 33px;
      text-decoration: none;
    }
  }

  .show_image_wrapper {
    padding-left: 1.75em;
    top: 6%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .news_wrapper {
    overflow: auto !important;
    min-height: 55em !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .sheet_show {
    overflow: auto;
  }

  .show_image_wrapper {
    margin-top: -55%;
    margin-left: -3%;
  }
}
