.dx-treelist-headers {
  .dx-header-row {
    background-color: #e8e8e8;
    color: #2a2a2a;
    font-weight: bold;
    &:not(:last-child) {
      background-color: #b91c1b;
      color: #f0f0f0;
    }
    td[rowspan] {
      text-align: center !important;
      vertical-align: middle;
    }
  }
}

.dx-treelist .dx-header-filter-empty {
  color: #d0d0d0;
}

.dx-treelist > td,
.dx-treelist > tr > td {
  background-color: transparent;
}

.dx-treelist .dx-row-alt > td,
.dx-treelist .dx-row-alt > tr > td,
.dx-treelist-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  background-color: rgba(0, 0, 0, 0.04);
}
