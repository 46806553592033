.admin-container {
  display: flex;
  flex-direction: column;

  .stats {
    align-self: flex-end;
  }
}

#adminTable {
  .dx-data-row > td {
    border-right: 0px;
    border-left: 0px;
    vertical-align: middle !important;
  }

  .dx-datagrid-headers {
    border: 0;
  }

  .dx-datagrid-headers > .dx-datagrid-content {
    border: 1px solid #ddd;
  }

  .dx-datagrid-header-panel {
    width: 100%;
    margin-top: 0;
  }
}

#pendingRequestsTable {
  .dx-data-row > td {
    border-right: 0px;
    border-left: 0px;
    vertical-align: middle !important;
  }

  .approval-icon {
    font-size: 20px;
    color: green;
  }

  .denial-icon {
    font-size: 20px;
    color: red;
    margin-left: 5px;
  }
}

.popup-label--upper {
  margin-top: 15px;
}

.popup-button {
  margin-top: 15px;
}

.checkbox {
  width: 20px !important;
  height: 20px;
  float: left;
  margin-right: 8px;
}
