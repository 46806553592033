.body--large {
  width: 100%;

  .dx-master-detail-cell {
    background-color: #f5f5f5 !important;

    .card {
      margin: 20px 0px 0px 0px;
      width: 100%;
    }

    .card-header > h2 {
      margin-left: 0px;
    }
  }

  .select-venues {
    margin-left: 20px;
    z-index: 10;
  }

  @supports (paint-order: stroke) {
    .plotly-stroke-text {
      stroke: white;
      stroke-width: 2;
      stroke-opacity: 0.8;
      paint-order: stroke;
    }
  }
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  background-color: #a8a8a8;
  cursor: pointer;
}
