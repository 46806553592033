#reports {
  .react-filters.react-filters-card {
    flex: 0 1 300px;
  }

  .react-result-card {
    flex: 1 1 auto;
  }

  .reports-view-changer {
    position: absolute;
    top: 27px;
    right: 0;
    width: 200px;
    z-index: 1;
    font-size: 12px;
  }

  .reports-result {
    font-size: 12px;
    font-weight: 700;
  }
}

.filter-group-collapsible-wrapper {

  .filter-group-wrapper {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-template-rows: 1fr auto ;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "arrow title"
      "arrow subtitle";
  }

  .filter-group-arrow { 
    grid-area: arrow; 
    display: grid;
    align-items: center;
  }

  .filter-group-title { grid-area: title; }

  .filter-group-subtitle { grid-area: subtitle; 
    text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

  // .filter-group-title {
  //   display: flex;
  //   align-items: center;

  //   width: 100%;

  //   font-weight: 600;
  //   cursor: pointer;

  //   h3 {
  //     flex: 1;
  //     margin-left: 10px;
  //   }

  //   &:hover {
  //     h3 {
  //       font-weight: 600;
  //     }
  //   }
  // }

  .filter-group-content {
    padding: 0 5px;
  }
}

@media (min-width: 992px) {
  #reports {
    .reports-view-changer {
      top: 10px;
    }

    .reports-result {
      position: absolute;
      top: 20px;

      z-index: 1;
    }
  }

  .filter-group-collapsible-wrapper {
    .filter-group-content {
      padding: 0 20px;
    }
  }
}
.reelRed{
  color: #b71f1c !important;
  float: right !important;
}

.reelGrey{
  color: #7a7a7a !important;
}

.reelDarkGrey{
  color: #4C4C4C !important;
}

.reelerRed{
  color: #b71f1c !important;
}

.filterText{
  color: hsl(0, 0%, 20%) !important;
  font-size: 12px !important;
  font-weight: 400px !important;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif !important;
  text-transform: none !important;
}

.filterArrow{
  color: rgb(215, 213, 213);
  float: right !important;
  font-size: 16px !important;
  font-weight: 400px !important;
  margin-top: 3px !important;
  margin-left: 3px !important;
}

.filterArrow :hover{
  color: #4C4C4C !important;
}

.filterCross{
  color: #b71f1c;
}

.dx-navigate-button{
  display: none !important;
}

