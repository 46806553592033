.floor-optimizer {
  .configuration-title {
    display: flex;
    align-items: center;
  }

  .floor-optimizer-index-info-icon {
    flex: 0 0 15px;
    margin-left: 10px;
  }

  .summary {
    margin: 30px 0 15px;
  }

  .status-score {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .status-score-element {
    margin-right: 15px;
  }

  .dx-datagrid-header-panel {
    margin-top: 30px;
  }
}
