/*	---------------------
	GENERAL
	--------------------- */

.sheet {
  background-color: #fff;
  padding: 2.2857em;
  position: relative;
}

.sheet--alt {
  background-color: transparent;
}

.sheet-header.sheet-header {
  color: $primary-color;
}

.sheet-header-dark-gray {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  position: relative;
  background-color: #323232;
  color: #fff;
  margin-bottom: 0;

  a {
    color: #fff;
  }

  h2,
  p {
    margin: 0;
  }

  p {
    font-size: 15px;
  }
}

.sheet-header.sheet-header--lower {
  margin-top: 0.35em;
}

.sheet--solo {
  margin-left: auto;
  margin-right: auto;
  max-width: 44.5em;
}

.sheet--bordered {
  border: 1px solid #ccc;
}

.sheet--content {
  margin-top: 130px;
  margin-bottom: 130px;
}

.sheet--shorter {
  @extend .sheet;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

// .sheet-header-inner { padding-left: 8px; padding-right: 8px; }

.slab {
  background-color: $secondary-color;
}

/*	---------------------
	LAYOUT
	--------------------- */

.cols {
  padding: 0;
}

.cols:after {
  content: "";
  display: table;
  clear: both;
}

.col,
.col-alt {
  display: inline-block;
}

.col {
  float: left;
}

.col-alt {
  float: right;
}

.col--one {
  width: 100%;
}

.col--two {
  width: 100%;
}

.col--four-fifths {
  width: 80%;
}

.col--one-fifth {
  width: 20%;
}

.footer .cols {
  padding: 2.2857em 0;
}

// .cols--padded { margin: -0.6875em -0.34375em; }
// .col--padded {padding: $data-padding  $data-padding / 2; }

@include respond-min-width(55em) {
  .col--one {
    width: 100%;
  }

  .col--two {
    width: 50%;
  }
  .col--three {
    width: 33.333%;
  }
  .col--four {
    width: 25%;
  }
  .col--special {
    width: 75%;
    padding-right: $padding * 2;
  }
  .col--special-alt {
    width: 25%;
  }
  .push {
    padding-left: $padding * 2;
  }
  .push-alt {
    padding-right: 1.6em;
  }
}

.pricing_tab_comp {
  height: 600px;
}
/*	---------------------
	OBJECT
	--------------------- */

.object-container {
  margin-left: -$page-padding;
  margin-right: -$page-padding;
  margin-top: $page-padding;
  margin-bottom: $page-padding;
}

.object {
  display: block;
  text-decoration: none;
}

.object-visual {
  float: left;
  margin-right: 0.25em;
}

.object-visual img {
  margin: 0;
  border: 3px solid #fff;
}

.object-body {
  padding: 0 1em;
  overflow: hidden;
}

a.object-visual:hover img {
  border: 3px solid #ededed;
}

@include respond-min-width(55em) {
  .object-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/*	---------------------
	GRID
	--------------------- */

.grid {
  @extend %group;
  @extend %list;
}

.grid-padded {
  margin: -$padding;
}

.grid-item-padded {
  padding: $padding;
}

.grid-container {
  overflow: hidden;
  margin-left: -$page-padding;
  margin-right: -$page-padding;
}

.grid-cols {
  margin: -$data-padding;
  padding: 0;
}

.grid-list {
  padding: 0;
  list-style: none;
}

.grid-item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

%grid-item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.grid-item--cols {
  padding: $data-padding;
}

.grid-item--cols-full {
  padding-top: $data-padding;
}

.grid-item--full,
.grid-item--one,
.grid-item--two,
.grid-item-sm-one,
.grid-item-sm-two,
.grid-item-sm-three,
.grid-item-sm-four,
.grid-item-sm-five,
.grid-item-sm-six,
.grid-item-md-one,
.grid-item-md-two,
.grid-item-md-three,
.grid-item-md-four,
.grid-item-md-five,
.grid-item-md-six,
.grid-item-lg-one,
.grid-item-lg-two,
.grid-item-lg-three,
.grid-item-lg-four,
.grid-item-lg-five,
.grid-item-lg-six {
  width: 100%;
  @extend %grid-item;
}

@include respond-min-width(43em) {
  .grid-item-sm-two,
  .grid-item-sm-three,
  .grid-item-sm-four,
  .grid-item-sm-five,
  .grid-item-sm-six,
  .grid-item-md-two,
  .grid-item-md-three,
  .grid-item-md-four,
  .grid-item-md-five,
  .grid-item-md-six,
  .grid-item-lg-two,
  .grid-item-lg-three,
  .grid-item-lg-four,
  .grid-item-lg-five,
  .grid-item-lg-six {
    float: left;
  }
  .grid-item-sm-two {
    width: 50%;
  }
  .grid-item-sm-three {
    width: 33.333%;
  }
  .grid-item-sm-four {
    width: 25%;
  }
  .grid-item-sm-five {
    width: 20%;
  }
  .grid-item-sm-six {
    width: 16.667%;
  }

  .grid-item-md-two,
  .grid-item-md-three,
  .grid-item-md-four,
  .grid-item-md-five,
  .grid-item-md-six {
    width: 50%;
  }
}

@include respond-min-width(48em) {
  .grid-item--two,
  .grid-item--three,
  .grid-item--four,
  .grid-item--three {
    width: 50%;
  }
}

@include respond-min-width(55em) {
  .grid-item-md-three {
    width: 33.333%;
  }
  .grid-item-md-four {
    width: 25%;
  }
  .grid-item-md-five {
    width: 20%;
  }
  .grid-item-md-six {
    width: 16.667%;
  }
  .grid-item-lg-two,
  .grid-item-lg-three,
  .grid-item-lg-four,
  .grid-item-lg-five,
  .grid-item-lg-six {
    width: 50%;
  }
}

@include respond-min-width(63em) {
  .grid-item-lg-three,
  .grid-item--three {
    width: 33.333%;
  }
  .misc .grid-item-lg-three {
    width: 40%;
  }
  .grid-item-lg-four,
  .grid-item--four {
    width: 24.728%;
  }
  .grid-item-lg-five,
  .grid-item--five {
    width: 20%;
  }
  .grid-item-lg-six {
    width: 16.667%;
  }
  .grid-item--two {
    width: 50%;
  }
}

/*	---------------------
	SOURCEORDER SHIFT
	--------------------- */

@include respond-min-width(35em) {
  .sos {
    display: flex;
  }
  .sos .sos-item:nth-child(1) {
    order: 1;
  }
  .sos .sos-item:nth-child(2) {
    order: -1;
  }
}

/*	---------------------
	FORM
	--------------------- */

.form-field select {
  height: 3em;
}

.form-field input,
.form-field textarea {
  border-top: 3px solid #ddd;
  outline: none;
}

.form-label label {
  margin: 0.5em 1.35em;
}

.has-error {
  .form-label label {
    color: #d50c0c;
  }
  .form-field input {
    border-bottom: 1px solid #d50c0c;
  }
}

@include respond-min-width(45em) {
  .form-row {
    display: flex;
    align-items: center;
  }
  .form-label,
  .form-field {
    float: left;
    width: 20%;
  }
  .form-field {
    width: 80%;
  }
  .form-row button[type="submit"] {
    margin-left: 20%;
  }
  .form-label label {
    margin-left: 0;
  }
}

input:focus,
textarea:focus {
  background-color: #ededed;
}

/*	---------------------
	SOCIAL-LIST
	--------------------- */

.social-list {
  margin-top: 0.5em;
}

.grid-item--social {
  width: auto;
}

.twitter {
  background-image: url("~twitter.svg");
}

.linkedin {
  background-image: url("~icon-linkedin.svg");
}

/*	---------------------
	TABLES
	--------------------- */

table {
  width: 100%;
}

thead > tr {
  background-color: #e8e8e8;
}

th,
td {
  padding: $data-padding;
  text-align: left;
}

tr.even {
  background-color: #f3f3f3;
}

th {
  font-size: 0.85em;
}

//responsive table
// .dataTables_wrapper th { float: left; display: block; }
// .dataTables_wrapper tbody tr { display: inline-block; }
// .dataTables_wrapper tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }

.trans-table > tr,
.trans-table tr,
.trans-table tr:nth-child(even) {
  background-color: transparent;
}

table a {
  color: #212121;
}

table a:hover {
  color: #212121;
}

td {
  font-size: 0.92857em;
}

/*	---------------------
	PAGINATION
	--------------------- */

.dataTables_info {
  color: #999;
  font-size: 0.875em;
}

.dataTables_paginate.paging_simple_numbers {
  margin-top: $padding;
  margin-bottom: $padding;
  margin-left: -0.5em;
  margin-right: -0.5em;
  z-index: 2;
}

.paginate_button {
  display: inline-block;
  padding: 0.25em 0.5em;
  color: #2a2a2a;
  background-color: transparent;
  text-decoration: none;
  z-index: 100;
}

.paginate_button.disabled {
  color: #999;
  cursor: default;
}

.paginate_button.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.paginate_button.previous:before {
  content: "‹\0000a0";
}

.paginate_button.next:after {
  content: "\0000a0›";
}

/*	---------------------
	TABS
	--------------------- */

.tabs-triggers {
  list-style: none;
  list-style-image: none;
  padding: 0;
}

.tabs-trigger {
  color: inherit;
}

.tabs-trigger.active {
  background-color: #fff;
}

.tabs--help {
  background-color: #dddddd;
}

.tabs-panes {
  width: 99.52%;
}

.tabs-panes--full {
  width: 100%;
}

.tabs-pane {
  max-height: 0;
}

.tabs-pane.active {
  max-height: 10000em;
  min-height: 43em;
}

.tabs-pane.sheet {
  padding: 0 2.2857em;
}

.tabs-pane.active.sheet {
  padding: 0 2.2857em;
}

.tabs-pane.active.sheet.with-react {
  padding: 2.2857em;
}

.tabs-pane-help {
  overflow: hidden;
}

.tabs-trigger--blocks {
  display: block;
  text-decoration: none;
  color: $primary-color;
  border-bottom: 0.5em solid #f0f0f0;
}

.tabs-trigger--blocks:after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s;
  min-height: 327px;
}

.tabs-trigger--blocks {
  &:hover:after {
    opacity: 1;
  }

  &.active:hover:after {
    opacity: 0;
  }
}

.tabs-trigger--blocks table {
  color: #2a2a2a;
}

.tabs-triggers-item--detail .tabs-trigger--blocks {
  border-bottom: 1em solid #f0f0f0;
}

.tabs-trigger--blocks.active {
  border-bottom: 0.51em solid #fff;
  cursor: default;
}

.tabs-triggers-item--detail .tabs-trigger--blocks.active {
  border-bottom: 1em solid #fff;
}

.tabs-triggers-item--detail {
  float: left;
  width: 50%;
  box-sizing: border-box;
}

.tabs-triggers-item--detail .tabs-trigger > .sheet > div {
  display: none;
}

.tabs-trigger--description {
  bottom: 15px;
  color: #000;
  font-size: 10px;
  position: absolute;
  width: 210px;
}

@include respond-min-width(43em) {
  .tabs-triggers--detail {
    margin: -0.5em;
  }
  .tabs-triggers-item--detail {
    width: 25%;
    float: left;
    margin: 0;
    padding: 0 0.5em;
  }
  .tabs-trigger-blocks {
    border-right: 0.5em solid #f0f0f0;
  }
}

@include respond-min-width(65em) {
  .tabs-triggers-item--detail .sheet {
    height: 22em;
  }
  .tabs-triggers-item--large .sheet {
    height: 25em;
  }
  .tabs-triggers-item--detail .tabs-trigger > .sheet > div {
    display: block;
  }
}

/*	---------------------
	ALERTS
	--------------------- */

.msg {
  box-shadow: 0.2em 0.5em 1em rgba(0, 0, 0, 0.2);
}

.msg-title {
  margin-bottom: 0.5em;
}

.notification {
  position: fixed;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 35em;
  z-index: 999;
  transition: top 0.2s ease-in-out;
}

.notification.active {
  top: 0;
}

.notification-close {
  background-image: url("~close.svg");
  background-color: transparent;
  position: absolute;
  top: $padding;
  right: $padding;
  text-indent: -999em;
  overflow: hidden;
}

.notification-close:hover {
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.2);
}

[class*="--success"] {
  background-color: #64b100;
  color: #fff;
}

a[class*="--success"] {
  &:hover,
  &:focus,
  &:active {
    background-color: darken(#64b100, 10%);
    color: #fff;
  }
}

[class*="--warning"] {
  background-color: #f0b606;
  color: #fff;
}

a[class*="--warning"] {
  &:hover,
  &:focus,
  &:active {
    background-color: darken(#f0b606, 10%);
    color: #fff;
  }
}

[class*="--error"] {
  background-color: #d50c0c;
  color: #fff;
}

a[class*="--error"] {
  &:hover,
  &:focus,
  &:active {
    background-color: darken(#d50c0c, 10%);
    color: #fff;
  }
}

[class*="--neutral"] {
  background-color: #3071de;
  color: #fff;
}

a[class*="--neutral"],
.button--neutral {
  &:hover,
  &:focus,
  &:active {
    background-color: darken(#3071de, 10%);
    color: #fff;
  }
}

[class*="--text-success"] {
  color: #64b100;
  // &:hover, &:focus, &:active { background-color: darken(#64b100, 10%); color: #fff; }
}

[class*="--text-warning"] {
  color: #f0b606;
  // &:hover, &:focus, &:active { background-color: darken(#f0b606, 10%); color: #fff; }
}

[class*="--text-error"] {
  color: #d50c0c;
  // &:hover, &:focus, &:active { background-color: darken(#d50c0c, 10%); color: #fff; }
}

[class*="--text-neutral"] {
  color: #3071de;
  // &:hover, &:focus, &:active { background-color: darken(#3071de, 10%); color: #fff; }
}

/*	---------------------
	ICONS
	--------------------- */

.icon-alt:after,
[class*="icon--"]:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.65em;
  width: 2.2em;
  height: 2.2em;
  background-size: contain;
  top: -0.1em;
}

.icon--like:before {
  background-image: url("~thumbs-up-01.svg");
}

.icon--like:hover:before {
  background-image: url("~thumbs-up-02.svg");
}

.icon--like.active:before {
  background-image: url("~thumbs-up-03.svg");
}

.icon--strike:before {
  background-image: url("~thumbs-down-01.svg");
}

.icon--strike:hover:before {
  background-image: url("~thumbs-down-02.svg");
}

.icon--strike.active:before {
  background-image: url("~thumbs-down-03.svg");
}

.icon--plus:before {
  background-image: url("~plus-01.svg");
}

.icon--cube:before {
  background-image: url("~cube.svg");
  width: 1.3em;
  height: 1.3em;
}

.icon--compare:before {
  background-image: url("~archive-01.svg");
  width: 1.25em;
  height: 1.25em;
}

.icon--camera:before {
  background-image: url("~camera-01.svg");
}

.icon--save:before {
  background-image: url("~savereport-01.svg");
  width: 1.25em;
  height: 1.25em;
}

.icon--save-alt:before {
  background-image: url("~query-01.svg");
  width: 1.25em;
  height: 1.25em;
}

.icon--more-filters:before {
  background-image: url("~filter-01.svg");
  width: 1.25em;
  height: 1.25em;
}

.icon--reset-filters:before {
  background-image: url("~close.svg");
  width: 1.25em;
  height: 1.25em;
}

.like,
.strike {
  opacity: 0.4;
  transition: 0.1s;
  width: 1em;
  height: 1em;
  display: inline-block;
  background-size: contain;
  cursor: pointer;
}

.like-strike-margin-top .button {
  margin-top: 10px;
  padding: 0px;
  margin-right: 5px;
}

.like-strike-margin-top .icon:before,
.icon-alt:after {
  margin-right: 0.15em;
}

.like {
  background-image: url("~thumbs-up-01.svg");
}

.like:hover {
  background-image: url("~thumbs-up-02.svg");
  opacity: 0.8;
}

.like.active {
  background-image: url("~thumbs-up-03.svg");
  opacity: 1;
}

.strike {
  background-image: url("~thumbs-down-01.svg");
}

.strike:hover {
  background-image: url("~thumbs-down-02.svg");
  opacity: 0.8;
}

.strike.active {
  background-image: url("~thumbs-down-03.svg");
  opacity: 1;
}

// .change-up, .change-down { position: relative; padding-left: 1.5em; }
// .change-up { color: $tertiary-color; }
// .change-down { color: $primary-color; }
// .change-up:before, .change-down:before { content: ''; position: absolute; left: 0; width: 0; height: 0; font-size: 0; line-height: 0; display: block; border-left: 5px solid transparent;  border-right: 5px solid transparent; }
// .change-up:before { top: 7px; border-bottom: 5px solid $tertiary-color; }
// .change-down:before { bottom: 6px; border-top: 5px solid $primary-color; }

.change-up,
.change-down,
.no-change {
  background-color: #64b100;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 0px 4px 0px 4px;
  font-weight: bolder;
}

.change-down {
  background-color: #d50c0c;
}

.no-change {
  background-color: #3071de;
}

.sorting,
.sorting_desc,
.sorting_asc {
  cursor: pointer;
}

.sorting:after,
.sorting_desc:after,
.sorting_asc:after {
  opacity: 0.3;
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 0.2em;
  cursor: pointer;
  vertical-align: middle;
  content: "";
  background-image: url("~sorting.png");
  background-size: contain;
}

.sorting_desc:after,
.sorting_asc:after,
.sorting:hover:after {
  opacity: 1;
}

.sorting_desc:hover:after,
.sorting_asc:hover:after {
  opacity: 0.3;
}

.sorting_desc:after {
  background-image: url("~sorting_desc.png");
}

.sorting_asc:after {
  background-image: url("~sorting_asc.png");
}

//default img game title detail
#game-logo {
  max-width: 333px;
}

td span[data-title]:after {
  content: attr(data-title);
  background-color: #3f3f3f;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 12px;
  position: absolute;
  display: inline-block;
  z-index: 20;
  padding: 6px 12px 6px 12px;
  white-space: nowrap;
  margin: -7px 0px 0px 12px;
  opacity: 0;
}

td span[data-title]:hover:after {
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in-out;
  -moz-transition: opacity 400ms ease-in-out;
  -o-transition: opacity 400ms ease-in-out;
  transition: opacity 400ms ease-in-out;
}
